import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex, Tag, TagLabel } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useUserState } from 'context/UserContext';
import { CheckCircleIcon, DeleteIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { ModalType, REPORT_ORDER_STATUS } from 'constants/common';
import { formatCurrencyToVND, formatDate } from 'utils/helpers';
const ProductOrderTable = ({ orderData, handleRejectOrder, handleSuccessOrder, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const { userInfo } = useUserState();

  const handleRowClick = (ticket, type) => {
    handleRejectOrder(ticket, type);
  };
  const handleRowClickSuccess = (ticket, type) => {
    handleSuccessOrder(ticket, type);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => {
          const orderStatus = info?.row?.original?.status;

          return (
            <Flex alignItems="center" margin={'auto'}>
              {orderStatus === REPORT_ORDER_STATUS.PROCESSING  && (
                <IconButton
                  bg="transparent"
                  onClick={() => {
                    handleRowClickSuccess(info?.row?.original, ModalType.Success);
                  }}
                >
                  <CheckCircleIcon color="green" boxSize={4} />
                </IconButton>
              )}

              {orderStatus === REPORT_ORDER_STATUS.PROCESSING && (
                <IconButton
                  bg="transparent"
                  onClick={() => {
                    handleRowClick(info?.row?.original, ModalType.Reject);
                  }}
                >
                  <NotAllowedIcon cursor="pointer" boxSize={4} />
                </IconButton>
              )}
            </Flex>
          );
        },
      }),
      columnHelper.accessor('orderNo', {
        header: 'Mã đơn hàng',
        cell: info => <Text color="#1951EE">{info.getValue()}</Text>,
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày bán',
        cell: info => {
          const dateValue = new Date(info.getValue());
          const adjustedDate = new Date(dateValue.setHours(dateValue.getHours() + 7));
          return <Text>{formatDate(adjustedDate, 'DD/MM/YYYY | hh:mm a')}</Text>;
        },
      }),
      columnHelper.accessor('member.username', {
        header: 'Người mua',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('productId.categoryId.title', {
        header: 'Gian hàng',
        cell: info => (
          <Text color="#1951EE" width="262px">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('productId.name', {
        header: 'Mặt hàng',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('quantity', {
        header: 'Số lượng',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('price', {
        header: 'Giá',
        cell: info => <Text width={'100px'}>{formatCurrencyToVND(info.getValue())}</Text>,
      }),
      columnHelper.accessor('totalPriceFinal', {
        header: 'Tổng tiền',
        cell: info => <Text>{formatCurrencyToVND(info.getValue())}</Text>,
      }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => {
          const statusValue = info.getValue();
          let colorScheme;
          let displayText;

          switch (statusValue) {
            case REPORT_ORDER_STATUS.SUCCESS:
              colorScheme = 'green';
              displayText = 'Thành công';
              break;
            case REPORT_ORDER_STATUS.PROCESSING:
              colorScheme = 'yellow';
              displayText = 'Đang xử lý';
              break;
            case REPORT_ORDER_STATUS.REJECTED:
              colorScheme = 'red';
              displayText = 'Thất bại';
              break;
            default:
              colorScheme = 'red';
              displayText = 'Không xác định';
          }
          return (
            <Tag borderRadius="full" variant="solid" colorScheme={colorScheme} width="120px">
              <TagLabel textAlign="center" width="100%">
                {displayText}
              </TagLabel>
            </Tag>
          );
        },
      }),
    ],
    [orderData]
  );
  const table = useReactTable({
    data: orderData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id} bg={'#EEF0FF'}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px" py={5}>
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                    textTransform="capitalize"
                    fontSize={14}
                    fontFamily={'Poppins, sans-serif'}
                    textColor={'#1951EE'}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' ↑',
                      desc: ' ↓',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id} width={cell.column.id === 'Booth' ? '262px' : 'auto'}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default ProductOrderTable;
