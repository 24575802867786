import { Button, Card, CardBody, CardHeader, Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import CouponTable from './components/Table';
import { useQueryGetCoupon } from 'services/coupon';

function Coupon() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: CouponData, refetch } = useQueryGetCoupon({ ...filter }, { enabled: isLoggedIn });

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Mã giảm giá
                </Text>
                <Button
                  variant="primary"
                  px={10}
                  maxH="40px"
                  alignSelf={'end'}
                  onClick={() => {
                    history.push('/admin/coupon-create');
                  }}
                >
                  <Text fontSize="md" fontWeight="bold" cursor="pointer">
                    Thêm mới
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <CouponTable
              CouponData={CouponData?.data}
              refetch={refetch}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={CouponData?.pagination?.page}
              pageLength={CouponData?.pagination?.pageSize}
              totalRecords={CouponData?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Coupon;
