import React, { useEffect, useState } from 'react';
import { Box, Flex, FormControl, Image, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import DatePicker from 'components/DatePicker/DatePicker';
import { CookieStorage } from 'utils/cookie-storage';
import { convertMMYYYYToMonth, convertToMMYYYY } from 'utils/helpers';
import moment from 'moment';
import { useUserState } from 'context/UserContext';
import { useQueryGetStatistics, useQueryGetStatisticsTransactionMonth } from 'services/service';
import ReactApexChart from 'react-apexcharts';
import Order from 'assets/svg/order.svg';
import Sales1 from 'assets/svg/sales1.svg';
import Sales2 from 'assets/svg/sales2.svg';
import Diagram from 'assets/svg/diagram.svg';
import { useHistory } from 'react-router-dom';

const initFiler = {
  date: moment().toDate().getTime(),
  // endTime: getInitFilerChart().endDate,
  // startTime: getInitFilerChart().startDate,
};

export default function Dashboard() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const [statisticFilter, setStatisticFilter] = useState(initFiler);
  const { userInfo } = useUserState();
  const history = useHistory();
  const { data: statistic } = useQueryGetStatistics({}, { enabled: isLoggedIn });
  const { data: statisticsTransactionMonth } = useQueryGetStatisticsTransactionMonth(
    {
      month: convertMMYYYYToMonth(convertToMMYYYY(statisticFilter.date)),
    },
    { enabled: isLoggedIn }
  );

  const [series, setSeries] = useState([
    {
      name: 'Series 1',
      data: [45, 52, 38, 45, 19, 23, 2],
    },
  ]);

  const options = {
    chart: {
      height: 280,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan'],
    },
  };

  // useEffect(() => {
  //   if (!CookieStorage.isAuthenticated()) {
  //     return history.push('/auth/sign-in');
  //   }
  // }, []);
  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth');
    }
  }, [isLoggedIn, history]);

  // const onChangeDate = type => date => {
  //   setStatisticFilter(prev => ({
  //     ...prev,
  //     ...(type === 'date' && { endTime:date}),
  //     [type]: date,
  //   }));
  // };
  const onChangeDate = date => {
    setStatisticFilter(prev => ({
      ...prev,
      // ...(type === 'startTime' && { endTime:date}),
      // [type]: date,
      date: date,
    }));
  };
  return (
    <>
      <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }}>
        <Box>
          <Box
            backdropFilter="blur(21px)"
            // boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
            // bg={sidebarBg}
            borderRadius={'10px'}
            py={'32px'}
            px={'16px'}
          >
            <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing="24px" mb="20px" mt="20px">
              <StatisticalData
                title="Số đơn hàng"
                value={statistic?.data?.totalOrder || 0}
                icon={<Image src={Order} alt="order" w="50px" h="50px" objectFit="contain" />}
              />
              <StatisticalData
                title="Doanh số"
                value={(statistic?.data?.totalSales || 0) + ' VNĐ'}
                icon={<Image src={Sales1} alt="Sale" w="50px" h="50px" objectFit="contain" />}
              />
            </SimpleGrid>
            <Card borderRadius="8px" bgColor="white" mb="20px">
              <Flex alignItems={'center'} gap={3}>
                <Image src={Diagram} alt="Diagram" w="20px" h="20px" objectFit="contain" />
                <Text fontWeight={600} fontSize={20}>
                  30 ngày gần nhất
                </Text>
              </Flex>
              <ReactApexChart options={options} series={series} type="area" height={350} />
            </Card>
            <Card borderRadius="8px" bgColor="white">
              <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Text fontWeight={'600'} fontSize={22}>
                  Kinh doanh tháng {convertToMMYYYY(statisticFilter.date)}
                </Text>
                <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }}>
                  <DatePicker
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    selectedDate={statisticFilter.date}
                    onChange={date => onChangeDate(date)}
                  />
                </FormControl>
              </Flex>
              <SimpleGrid columns={{ sm: 1, md: 3, xl: 3 }} spacing="24px" mb="20px" mt="20px">
                <StatisticalData
                  icon={<Image src={Order} alt="order" w="50px" h="50px" objectFit="contain" />}
                  title="Số đơn hàng"
                  value={statisticsTransactionMonth?.data?.totalOrder || 0}
                />
                <StatisticalData
                  icon={<Image src={Sales1} alt="Sales1" w="50px" h="50px" objectFit="contain" />}
                  title="Doanh số"
                  value={(statisticsTransactionMonth?.data?.totalSalesInMonth || 0) + ' VNĐ'}
                />
                <StatisticalData
                  icon={<Image src={Sales2} alt="Sales2" w="50px" h="50px" objectFit="contain" />}
                  title="Sàn tạm giữ"
                  value={(statisticsTransactionMonth?.data?.totalHold || 0) + ' VNĐ'}
                />
              </SimpleGrid>
            </Card>
          </Box>
        </Box>
      </Flex>
    </>
  );
}

const StatisticalData = props => {
  const { title, value, icon } = props;
  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <Card minH="125px" bgColor="white" borderRadius="8px">
      <Flex alignItems={'center'}>
        {icon}
        <Flex flexDirection={'column'} direction="row" mt={'16px'} ml={'32px'}>
          <Text fontSize="5xl" color={textColor} fontWeight="900">
            {value}
          </Text>
          <Text fontSize="xl" color={textColor} fontWeight="normal">
            {title}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
