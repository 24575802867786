import axios from 'axios';
import qs from 'qs';

import { CookieStorage } from './cookie-storage';
import { ErrorApi } from 'constants/error';
import { ROOT_API, SITE_URL, ToastStatus } from 'constants/common';
import { toast } from 'components/Toast';
import { getMemberToken, getToken } from './authentication';

const axiosConfig = {
  baseURL: ROOT_API,
  timeout: 120000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
};

export const request = axios.create(axiosConfig);

request.interceptors.request.use(
  function (config) {
    const token = getToken();
    const memberToken = getMemberToken();
    const accessToken = CookieStorage.getAccessToken();
    if (token && config?.headers) {
      config.headers['xToken'] = token;
    } else if ((accessToken) && config?.headers) {
      config.headers['mToken'] = accessToken;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (errorResponse) {
    const { error, errors, msg } = errorResponse?.response?.data || {};
    const message = ErrorApi?.[error || errors?.[0]?.msg || msg] || error || errors?.[0]?.msg || msg;

    toast.showMessageError(message);

    if (errorResponse?.response?.status === 401) {
      CookieStorage.clearSession();
      CookieStorage.clearSession(undefined, '.vebo888b.asia');
      localStorage.clear();
      window.location.href = SITE_URL;
    }

    return Promise.reject(error);
  }
);
