import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
  FormControl,
  FormLabel,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { useCreateCategoryMutation } from 'services/category';
import { importExcel, useRejectOrderMutation, useSubmitOrderMutation } from 'services/product';
import { useDropzone } from 'react-dropzone';
import { BsUpload } from 'react-icons/bs';

const OrderModal = ({ isOpen, orderDetail, onClose, refetch, modalType }) => {
  const cancelRef = React.useRef();
  const createCategoryMutation = useCreateCategoryMutation();
  const rejectOrderMutation = useRejectOrderMutation();
  const submitOrderMutation = useSubmitOrderMutation();
  const [error, setError] = useState({
    orderFile: null,
  });
  const [isDragEnter, setIsDragEnter] = useState(false);
  const [file, setFile] = useState();
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
    },
  });
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
    },
    onDragEnter: () => {
      setIsDragEnter(true);
    },
    onDragLeave: () => {
      setIsDragEnter(false);
    },
    onDrop: async acceptedFiles => {
      setIsDragEnter(false);
      setError({ orderFile: null });
      setFile(acceptedFiles[0]?.name);
      const formData = new FormData();
      formData.append('orderFile', acceptedFiles[0]);
      await importExcel(formData)
        .then((res) => {
          toast.showMessageSuccess('Tải lên file thành công');
          setValue('path', res?.data);
        })
        .catch(() => {
          setError({ path: 'Tải lên file thất bại' });
        });
    },
  });

  useEffect(() => {
    if (orderDetail) {
      reset({ orderId: orderDetail?._id });
    }
  }, [orderDetail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${modalType ? 'Huỷ' : ''} đơn hàng thành công`);
    refetch?.();
    onClose(ModalType.Reject);
  };

  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${modalType ? 'Huỷ' : ''} đơn hàng thất bại`
    );
  };

  const onSubmit = async values => {
    if (modalType) {
      const confirmDelete = window.confirm('Bạn có chắc chắn muốn Huỷ đơn hàng này không?');
      if (confirmDelete) {
        rejectOrderMutation.mutate(
          {
            orderId: values?.orderId,
            reason: values?.reason || 'Đơn hàng xử lý thất bại'
          },
          {
            onSuccess: () => {
              handleSuccess();
              refetch?.();
            },
            onError: error => handleError(error),
          }
        );
      }
    } else {
      const confirmDelete = window.confirm('Bạn có chắc chắn muốn đơn hàng này trạng thái thành công?');
      if (confirmDelete) {
        submitOrderMutation.mutate(
          { ...values },
          {
            onSuccess: () => {
              handleSuccess();
              refetch?.();
            },
            onError: error => handleError(error),
          }
        );
      }
    }
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Reject);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}  >
          <AlertDialogHeader textTransform="uppercase" color={'#1951ee'}>Đơn hàng {modalType ? 'thất bại' : 'Thành công'}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody >
            <Text fontSize={16} color={'#3a3a3a'} fontWeight={'bold'} mb={4}>Gian hàng: {orderDetail?.productId?.categoryId?.title}</Text>
            <Text fontSize={16} color={'#3a3a3a'} fontWeight={'bold'} mb={4}>Mặt hàng: {orderDetail?.productId?.name}</Text>
            {modalType ? (
              <InputController control={control} name="reason" label="Lý do huỷ đơn hàng" type='textarea' />
            ) : (
              <FormControl pt={4}>
                <FormLabel minW="150px">Tệp Excel</FormLabel>
                <Box
                  {...getRootProps()}
                  cursor="pointer"
                  borderWidth="1px"
                  borderStyle="dashed"
                  borderRadius="12px"
                  padding="55px"
                  textAlign="center"
                  height="210px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <input {...getInputProps()} />
                  <BsUpload size={40} />
                  {isDragEnter ? (
                    <p>Thả tệp Excel của bạn</p>
                  ) : (
                    <>
                      <p>
                        <Text as="span" color="red.400">
                          Tải tệp lên
                        </Text>
                        &nbsp;hoặc kéo và thả
                      </p>
                    </>
                  )}
                  {file && <Text pt={2}>Tệp đã chọn: {file}</Text>}
                </Box>
                {!!error.path && (
                  <Text pt={1} color={'red.500'} fontSize="13px">
                    {error.path}
                  </Text>
                )}
              </FormControl>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Reject);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createCategoryMutation.isPending || rejectOrderMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              Xác nhận
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default OrderModal;
