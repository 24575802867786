import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex, Tag, TagLabel, Icon } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { MdOutlineCurrencyExchange } from 'react-icons/md';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDeleteCustomerApi } from 'services/user';
import { ModalType } from 'constants/common';
import { formatCurrencyToVND, formatDate } from 'utils/helpers';

const ReviewTable = ({ reviewData, refetch, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteCustomerApiMutation = useDeleteCustomerApi();

  const { userInfo } = useUserState();
  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  // const handleDelete = async data => {
  //   const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa khách hàng này không?');

  //   if (confirmDelete) {
  //     deleteCustomerApiMutation.mutate(
  //       { username: data?.username },
  //       {
  //         onSuccess: () => {
  //           toast.showMessageSuccess('Xóa khách hàng thành công');
  //           refetch?.();
  //         },
  //       }
  //     );
  //   }
  // };
  const columns = useMemo(
    () => [
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            {/* <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_MEMBER'}>
              <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Adjust)}>
                <MdOutlineCurrencyExchange cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper> */}
            {/* <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton bg="transparent" isActive={isOpen} as={IconButton}>
                    <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Block)}>
                      <FaHistory cursor="pointer" boxSize={4} />
                    </IconButton>
                  </MenuButton>
                  <MenuList>
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_MEMBER'}>
                      <MenuItem onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Preview)}>Chi tiết khách hàng</MenuItem>
                    </AuthorizationComponentWrapper>
                  </MenuList>
                </>
              )}
            </Menu> */}
            {/* <IconButton
                    bg="transparent"
                    onClick={() => {
                      handleDelete(info?.row?.original);
                    }}
                  >
                    <DeleteIcon color="red.400" boxSize={4} />
                  </IconButton> */}
          </Flex>
        ),
      }),
      columnHelper.accessor('product._id', {
        header: 'Mã đơn hàng',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('product.name', {
        header: 'Tên gian hàng',
        cell: info => (
          <Text color="#1951EE" width="252px">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('content', {
        header: 'Comment',
        cell: info => <Text width="252px">{info.getValue()}</Text>,
      }),
      columnHelper.accessor('rate', {
        header: 'Rating (Sao)',
        cell: info => {
          const rating = info.getValue();
          return (
            <Flex>
              {[...Array(5)].map((_, index) => (
                <Icon key={index} as={index < rating ? FaStar : FaRegStar} color={'#F8CE00'} boxSize={4} />
              ))}
            </Flex>
          );
        },
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => {
          const dateValue = new Date(info.getValue());
          const adjustedDate = new Date(dateValue.setHours(dateValue.getHours() + 7));
          return <Text>{formatDate(adjustedDate, 'DD/MM/YYYY | hh:mm')}</Text>;
        },
      }),
    ],
    [reviewData]
  );
  const table = useReactTable({
    data: reviewData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id} bg={'#EEF0FF'}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px" py={5}>
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                    textTransform="capitalize"
                    fontSize={14}
                    fontFamily={'Poppins, sans-serif'}
                    textColor={'#1951EE'}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' ↑',
                      desc: ' ↓',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id} width={cell.column.id === 'boothName' ? '252px' : 'auto'}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default ReviewTable;