import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
  Text,
  Checkbox,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { AddProductFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateUserMutation, useUpdateUserMutation } from 'services/user';
import TableStoreManagement from './TableStoreManagement';

const StoreManagementModal = ({ userInfo, isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createUserMutation = useCreateUserMutation();
  const updateUserMutation = useUpdateUserMutation();
  const { control, handleSubmit, reset, setValue, clearErrors } = useForm({
    resolver: yupResolver(AddProductFormValidate),
    defaultValues: {},
  });
  const [itemUpdate, setItemUpdate] = useState();

  const handleUpdateItem = (item, type) => {
    setItemUpdate(item);
    clearErrors();
    setValue('name', item?.name);
    setValue('price', item?.price);
  };

  const handleSuccess = () => {
    toast.showMessageSuccess(`Tạo quản trị viên thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };

  const handleError = error => {
    toast.showMessageError(error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Tạo quản trị viên thành công`);
  };

  const onSubmit = async values => {
    createUserMutation.mutate(
      { ...values, role: values.role?.value, ...(values.brand?.value && { brand: values.brand?.value }) },
      {
        onSuccess: () => handleSuccess(),
        onError: error => handleError(error),
      }
    );
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'800px'}>
          <AlertDialogHeader textColor={'#1951EE'}>Quản lý mặt hàng / kho</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody gap={4}>
            <Text fontWeight={600}>Gian hàng</Text>
            <Box display={'flex'} bg={'#ECF5FF'} color={'#1951EE'} rounded={6} py={3} px={2} my={3} border={'1px solid #1951EE'}>
              <Text flex={1}>Gmail NEW iOS US và random, có API đọc mail không đăng nhập</Text>
              <Text color={'white'} bg={'#1951EE'} fontSize={12} rounded={20} py={'1px'} px={2} display={'inline'}>
                Sản phẩm
              </Text>
            </Box>
            <Flex fontSize={12} alignItems={'center'} gap={2} my={6}>
              <Checkbox />
              <Text>
                Sử dụng kho hàng riêng
                <Text color={'#FD5252'} display={'inline'}>
                  (Thiết lập này yêu cầu bạn có server kho hàng riêng và có API lấy hàng cung cấp cho sàn)
                </Text>
              </Text>
            </Flex>
            {!itemUpdate ? (
              <>
                <TableStoreManagement
                  userInfo={userInfo}
                  users={[]}
                  handleUpdateCategory={handleUpdateItem}
                  // refetch={refetch}
                />
                <Flex mt={6} gap={4}>
                  <InputController
                    control={control}
                    isRequired
                    name="name"
                    styleContainer={{
                      marginBottom: '20px',
                    }}
                    placeholder={'Nhập tên mặt hàng'}
                  />
                  <InputController control={control} isRequired name="price" placeholder={'Nhập giá tiền'} />
                </Flex>
                <Button
                  px={10}
                  fontSize={12}
                  fontWeight={'normal'}
                  variant={'primary'}
                  isLoading={createUserMutation.isPending || updateUserMutation.isPending}
                  onClick={handleSubmit(onSubmit)}
                >
                  {'Thêm'}
                </Button>
              </>
            ) : (
              <>
                <Text fontWeight={600} mb={3} mt={6}>Chỉnh sửa mặt hàng</Text>
                <Flex gap={4}>
                  <InputController
                    control={control}
                    isRequired
                    name="name"
                    styleContainer={{
                      marginBottom: '20px',
                    }}
                    placeholder={'Nhập tên mặt hàng'}
                  />
                  <InputController control={control} isRequired name="price" placeholder={'Nhập giá tiền'} />
                </Flex>
                <Flex alignItems={'center'} gap={2}>
                  <Button
                    px={10}
                    fontSize={12}
                    fontWeight={'normal'}
                    variant={'primary'}
                    isLoading={createUserMutation.isPending || updateUserMutation.isPending}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {'Xác nhận'}
                  </Button>
                  <Button
                    px={10}
                    fontSize={12}
                    fontWeight={'normal'}
                    color={'#1951EE'}
                    bg={'transparent'}
                    border={'1px solid #ccc'}
                    isLoading={createUserMutation.isPending || updateUserMutation.isPending}
                    onClick={() => {
                      setValue('name', '');
                      setValue('price', '');
                      setItemUpdate(null);
                    }}
                  >
                    {'Huỷ'}
                  </Button>
                </Flex>
              </>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Đóng
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default StoreManagementModal;
