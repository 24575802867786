import React from 'react';
import {
  AdobexdLogo,
  AtlassianLogo,
  CartIcon,
  ClockIcon,
  CreativeTimLogo,
  CreditIcon,
  DashboardLogo,
  DashboardLogoWhite,
  DocumentIcon,
  GlobeIcon,
  PersonIcon,
} from 'components/Icons/Icons';
import { MdViewList } from 'react-icons/md';
import Dashboard from 'views/Dashboard/Dashboard.js';
import SignUp from 'views/Pages/SignUp.js';
import SignIn from 'views/Pages/SignIn';
import Profile from 'views/Dashboard/Profile';
import { Roles } from 'constants/common';
import Members from 'views/Dashboard/Members/Members';
import Department from 'views/Dashboard/Admin';
import HistoryRentPhoneBo from 'views/Dashboard/HistoryRentPhoneBO/HistoryRentPhoneBo';
import HistoryGeneral from 'views/Dashboard/HistoryGeneral/HistoryGeneral';
import WhiteListIp from 'views/WhilteListIp';
import CustomerApi from 'views/CustomerApi';
import CreateDepartment from 'views/Dashboard/Admin/components/CreateDepartment';
import ProductOrder from 'views/Dashboard/ProductOrder';
import ServiceOrder from 'views/Dashboard/ServiceOrder';
import PreOrder from 'views/Dashboard/Pre-order';
import Complaint from 'views/Dashboard/Complaint';
import Reseller from 'views/Dashboard/Reseller';
import Review from 'views/Dashboard/Review';
import Coupon from 'views/Dashboard/Coupon';
import CreateCoupon from 'views/Dashboard/Coupon/components/CreateCoupon';
import TopBooth from 'views/Dashboard/TopBooth';
import UpdateDepartment from 'views/Dashboard/Admin/components/UpdateDepartment';

var dashRoutes = [
  {
    name: 'Sales',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [],
  },
  {
    path: '/dashboard',
    name: 'Sales',
    icon: <GlobeIcon color="inherit" />,
    layout: '/admin',
    component: Dashboard,
    role: [],
  },
  {
    name: 'Shop',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [],
  },
  {
    path: '/department',
    name: 'Quản lý gian hàng',
    icon: <DocumentIcon color="inherit" />,
    layout: '/admin',
    component: Department,
    role: [],
    groupName: 'VIEW_LIST_ADMIN',
  },
  {
    path: '/create/department',
    name: 'Quản lý gian hàng',
    icon: <DocumentIcon color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: CreateDepartment,
    role: [],
    groupName: 'VIEW_LIST_ADMIN',
  },
  {
    path: '/update/department/:id',
    name: 'Quản lý gian hàng',
    icon: <DocumentIcon color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: UpdateDepartment,
    role: [],
    groupName: 'VIEW_LIST_ADMIN',
  },
  {
    path: '/order-product',
    name: 'Đơn hàng sản phẩm',
    icon: <DashboardLogoWhite color="inherit" />,
    layout: '/admin',
    component: ProductOrder,
    role: [],
    groupName: 'VIEW_LIST_PRODUCT_CATEGORY',
  },
  {
    path: '/order-service',
    name: 'Đơn hàng dịch vụ',
    icon: <CartIcon color="inherit" />,
    layout: '/admin',
    component: ServiceOrder,
    role: [],
    groupName: 'VIEW_LIST_PRODUCT_CATEGORY',
  },
  {
    path: '/pre-orders',
    name: 'Đặt trước',
    icon: <AdobexdLogo color="inherit" />,
    layout: '/admin',
    component: PreOrder,
    role: [],
    groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
  },
  {
    path: '/complaint',
    name: 'Khiếu nại',
    icon: <CreditIcon color="inherit" />,
    layout: '/admin',
    component: Complaint,
    role: [],
    groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
  },
  {
    path: '/reseller',
    name: 'Quản lý reseller',
    icon: <ClockIcon color="inherit" />,
    layout: '/admin',
    component: Reseller,
    role: [],
    groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
  },
  {
    path: '/review',
    name: 'Đánh giá',
    icon: <DashboardLogo color="inherit" />,
    layout: '/admin',
    component: Review,
    role: [],
    groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
  },
  {
    path: '/coupon',
    name: 'Mã giảm giá',
    icon: <AtlassianLogo color="inherit" />,
    layout: '/admin',
    component: Coupon,
    role: [],
    groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
  },
  {
    path: '/coupon-create',
    name: 'Mã giảm giá',
    icon: <AtlassianLogo color="inherit" />,
    layout: '/admin',
    component: CreateCoupon,
    role: [],
    redirect: true,
    groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
  },
  // {
  //   path: '/giai-hang-top',
  //   name: 'Gian hàng top 1',
  //   icon: <CreativeTimLogo color="inherit" />,
  //   layout: '/admin',
  //   component: TopBooth,
  //   role: [],
  //   groupName: ['VIEW_LIST_MEMBER', 'VIEW_DETAIL_MEMBER'],
  // },
  {
    name: '',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
      {
        path: '/general-history',
        name: 'Lịch sử chung',
        icon: <MdViewList color="inherit" />,
        secondaryNavbar: true,
        layout: '/admin',
        redirect: true,
        component: HistoryGeneral,
        role: [],
        groupName: 'RESET_PASSWORD_ADMIN',
      },
      {
        path: '/general-history-bo',
        name: 'Lịch sử chung',
        redirect: true,
        icon: <MdViewList color="inherit" />,
        secondaryNavbar: true,
        layout: '/admin',
        component: HistoryRentPhoneBo,
        role: [Roles.ADMIN, Roles.MANAGER],
        groupName: 'RESET_PASSWORD_ADMIN',
      },
      {
        path: '/profile',
        name: 'Thông tin tài khoản',
        redirect: true,
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        layout: '/admin',
        component: Profile,
        // role: [Roles.ADMIN, Roles.MANAGER, Roles.USER, Roles.GUEST],
        groupName: 'RESET_PASSWORD_ADMIN',
      },
      {
        path: '/login',
        layout: '/auth',
        redirect: true,
        component: SignIn,
      },
      {
        path: '/sign-up',
        layout: '/auth',
        redirect: true,
        component: SignUp,
      },
      {
        path: '/admin/sign-up',
        layout: '/auth',
        redirect: true,
        component: SignUp,
      },
    ],
  },
];

export default dashRoutes;
