import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getProfile = () => request.get('/api/v1/user/profile');
export const updateProfile = async data => {
  return await request.post('/api/v1/user/change-information', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateProfileMember = data => request.post('/api/v1/member/change-information', data);
export const register = data => request.post('/api/v1/member/register', data);
export const sentOtpEmailresetPassword = data => request.post('/api/v1/member/forget-password', data);
export const setNewPassword = data => request.post('/api/v1/member/set-new-password', data);
export const changePassword = data => request.post('/api/v1/user/change-pw', data);
export const changePasswordVersionV2 = data => request.post('/api/v1/user/change-pw', data);
export const login = data => request.post('/api/v1/user/login', data);
export const loginMember = data => request.post('/api/v1/member/login', data);
export const bookmarker = (params) => request.get('/api/v1/setting/book-markers-admin', { params });
export const users = (params) => request.get('/api/v1/user/users', { params });
export const createBookmarker = data => request.post('/api/v1/setting/book-markers', data);
export const deleteBookmarker = data => request.post('/api/v1/setting/book-markers/delete', data);
export const createUser = data => request.post('/api/v1/user/register', data);
export const updateUser = data => request.post('/api/v1/user/update', data);
export const resetPassword = data => request.post('/api/v1/user/reset-password', data);
export const deleteUser = data => request.post('/api/v1/user/delete', data);
export const assignPermission = data => request.post('/api/v1/user/assign-permissions', data);
export const changePasswordMember = data => request.post('/api/v1/member/change-password', data);
export const changePasswordAdmin = data => request.post('/api/v1/user/change-pw', data);
export const getProfileMember = () => request.get('/api/v1/member/profile');
export const exportTempleteMember = (params, headers) => request.get('/api/v1/mission-member/member-histories', {params, ...headers});
export const listCustomerApi = (params) => request.get('/api/v1/member/api-members', {params});
export const deleteCustomerApi = data => request.post('/api/v1/member/delete', data);
export const createCustomerApi = data => request.post('/api/v1/member/create-api-member', data);
export const resetDefaulPassword = data => request.post('/api/v1/member/reset-password', data);
// Mutation
export const useRegisterMutation = () => useMutation({ mutationFn: register });
export const useChangePasswordMemberMutation = () => useMutation({ mutationFn: changePasswordMember });
export const useChangePasswordAdminMutation = () => useMutation({ mutationFn: changePasswordAdmin });
export const useUpdateProfileMemberMutation = () => useMutation({ mutationFn: updateProfileMember });
export const useLoginMutation = () => useMutation({ mutationFn: login });
export const useLoginMemberMutation = () => useMutation({ mutationFn: loginMember });
export const useSentOtpEmailResetPasswordMemberMutation = () => useMutation({ mutationFn: sentOtpEmailresetPassword });
export const useChangePasswordMutation = () => useMutation({ mutationFn: changePassword });
export const useChangePasswordV2Mutation = () => useMutation({ mutationFn: changePasswordVersionV2 });
export const useUpdateProfileMutation = () => useMutation({ mutationFn: updateProfile });
export const useCreateBookmarkerMutation = () => useMutation({ mutationFn: createBookmarker });
export const useDeleteBookmarkerMutation = () => useMutation({ mutationFn: deleteBookmarker });
export const useCreateUserMutation = () => useMutation({ mutationFn: createUser });
export const useUpdateUserMutation = () => useMutation({ mutationFn: updateUser });
export const useDeleteUserMutation = () => useMutation({ mutationFn: deleteUser });
export const useAssignPermissionMutation = () => useMutation({ mutationFn: assignPermission });
export const useResetPasswordUserMutation = () => useMutation({ mutationFn: resetPassword });
export const useSetNewPasswordMutation = () => useMutation({ mutationFn: setNewPassword });
export const useExportCoinTemplateMemberMutation = (params, headers) => useMutation({ mutationFn: () => exportTempleteMember(params, headers) });
export const useDeleteCustomerApi = () => useMutation({ mutationFn: deleteCustomerApi });
export const useCreateCustomerApi = () => useMutation({ mutationFn: createCustomerApi });
export const useResetDefaultPassword = () => useMutation({ mutationFn: resetDefaulPassword });

// Query
export const useQueryUserProfile = (options = {}) => useQuery({ queryKey: ['getUserProfile'], queryFn: () => getProfile(), ...options });
export const useQuerGetUsers = (params, options = {}) => useQuery({ queryKey: ['getUsers', params], queryFn: () => users(params), ...options });
export const useQueryBookMarker = (params, options = {}) => useQuery({ queryKey: ['getBookMarker', params], queryFn: () => bookmarker(params), ...options });
export const useQueryMemberProfile = (options = {}) => useQuery({ queryKey: ['getProfileMember'], queryFn: () => getProfileMember(), ...options });
export const useQueryGetListCustomerApi = (params={},options = {}) => useQuery({ queryKey: ['LIST_CUSTOMER_API',params], queryFn: () => listCustomerApi(params), ...options });
