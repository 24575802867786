import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import ResellerTable from './components/Table';
import { useQueryGetReseller } from 'services/seller';
import { statusOptions } from 'constants/common';


function Reseller() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [status, setStatus] = useState('');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: resellerData, refetch } = useQueryGetReseller({ ...filter, searchKeyword: filter.searchKeyword }, { enabled: isLoggedIn });

  const handleStatusChange = value => {
    setStatus(value);
    setFilter(prevFilter => ({
      ...prevFilter,
      status: value,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Quản lý Reseller
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack width="100%">
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <Select placeholder="Trạng thái" value={status} onChange={e => handleStatusChange(e.target.value)}>
                        {statusOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <ResellerTable
              resellerData={resellerData?.data || []}
            // refetch={refetch}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={resellerData?.pagination?.page}
              pageLength={resellerData?.pagination?.pageSize}
              totalRecords={resellerData?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Reseller;
