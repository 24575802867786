import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex, Tag, TagLabel, Icon } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { MdOutlineCurrencyExchange } from 'react-icons/md';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDeleteCustomerApi } from 'services/user';
import { COUPON_TYPE, ModalType } from 'constants/common';
import { formatCurrencyToVND, formatDate } from 'utils/helpers';
import { toast } from 'components/Toast';
import { useDeleteCouponMutation } from 'services/coupon';

const CouponTable = ({ CouponData, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteCouponMutation = useDeleteCouponMutation();

  const { userInfo } = useUserState();
  const handleDelete = async data => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa mã giảm giá này không?');

    if (confirmDelete) {
      deleteCouponMutation.mutate(
        { id: data?._id },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Xóa mã giảm giá thành công');
            refetch?.();
          },
        }
      );
    }
  };
  const columns = useMemo(
    () => [
      columnHelper.accessor('', {
        header: 'Stt',
        cell: info => <Text>{info.row.index + 1}</Text>,
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" margin={'auto'}>
            {/* <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_MEMBER'}>
              <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Adjust)}>
                <MdOutlineCurrencyExchange cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper> */}
            {/* <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton bg="transparent" isActive={isOpen} as={IconButton}>
                    <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Block)}>
                      <FaHistory cursor="pointer" boxSize={4} />
                    </IconButton>
                  </MenuButton>
                  <MenuList>
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_MEMBER'}>
                      <MenuItem onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Preview)}>Chi tiết khách hàng</MenuItem>
                    </AuthorizationComponentWrapper>
                  </MenuList>
                </>
              )}
            </Menu> */}
            <IconButton
              bg="transparent"
              onClick={() => {
                handleDelete(info?.row?.original);
              }}
            >
              <DeleteIcon color="red.400" boxSize={4} />
            </IconButton>
          </Flex>
        ),
      }),
      columnHelper.accessor('code', {
        header: 'Mã',
        cell: info => <Text color="#1951EE">{info.getValue()}</Text>,
      }),
      columnHelper.accessor('type', {
        header: 'Loại giảm giá',
        cell: info => {
          const value = info.getValue();
          return value === COUPON_TYPE.AMOUNT ? 'Giảm theo số tiền' : 'Giảm theo phần trăm';
        },
      }),
      columnHelper.accessor('dateStart', {
        header: 'Bắt đầu',
        cell: info => <Text>{formatDate(info.getValue(), 'DD/MM/YYYY')}</Text>,
      }),
      columnHelper.accessor('dateEnd', {
        header: 'Kết thúc',
        cell: info => <Text>{formatDate(info.getValue(), 'DD/MM/YYYY')}</Text>,
      }),
      columnHelper.accessor('amount', {
        header: 'Tỷ lệ giảm',
        cell: info => {
          const value = info.getValue();
          return <Text>{value ? `${value}%` : ''}</Text>;
        },
      }),
      columnHelper.accessor('max', {
        header: 'số tiền tối đa',
        cell: info => <Text>{formatCurrencyToVND(info.getValue())}</Text>,
      }),
      columnHelper.accessor('quantityUsing', {
        header: 'Số lần sử dụng',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('description', {
        header: 'Mô tả',
        cell: info => (
          <Text color="#1951EE" width="200px">
            {info.getValue()}
          </Text>
        ),
      }),
    ],
    [CouponData]
  );
  const table = useReactTable({
    data: CouponData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id} bg={'#EEF0FF'}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px" py={5}>
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                    textTransform="capitalize"
                    fontSize={14}
                    fontFamily={'Poppins, sans-serif'}
                    textColor={'#1951EE'}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' ↑',
                      desc: ' ↓',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id} width={cell.column.id === 'description' ? '262px' : 'auto'}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default CouponTable;
