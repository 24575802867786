import { Button, Card, CardBody, CardHeader, Flex, FormControl, Input, Select, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import ProductOrderTable from './components/Table';
import { useQueryGetOrder } from 'services/coupon';
import { ModalType, statusOrderOptions } from 'constants/common';
import OrderModal from './components/OrderModal';

function ProductOrder() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [ordeCode, setOrderCode] = useState('');
  const [buyerName, setBuyerName] = useState('');
  const [status, setStatus] = useState('');
  const [type, settype] = useState('');
  const [orderEditing, setOrderEditing] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Success]: onOpenCreateModal,
      [ModalType.Reject]: onOpenCreateModal,
    }),
    [onOpenCreateModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Reject]: onCloseCreateModal,
      [ModalType.Success]: onCloseCreateModal,
    }),
    [onCloseCreateModal]
  );

  const { data: orderData, refetch } = useQueryGetOrder({ ...filter, searchKeyword: filter.searchKeyword, typeProduct: 'PRODUCT' }, { enabled: isLoggedIn });

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: ordeCode,
      searchKeywordName: buyerName, // Search by buyer name
      status: status,
    });
  };


  const handleRejectItem = (size, modalType) => {
    openModal?.[modalType]?.();
    settype(modalType);
    setOrderEditing(size);
  };
  const handleSuccessItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setOrderEditing(size);
  };
  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    settype('');
    setOrderEditing(null);
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Sản phẩm đã bán
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack width="100%">
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl flex={{ base: '1 1 100%', md: '1 1 30%' }}>
                      <Input placeholder="Nhập mã đơn hàng" value={ordeCode} onChange={e => setOrderCode(e.target.value)} />
                    </FormControl>
                    <FormControl flex={{ base: '1 1 100%', md: '1 1 30%' }}>
                      <Input placeholder="Nhập tên người mua" value={buyerName} onChange={e => setBuyerName(e.target.value)} />
                    </FormControl>
                    <FormControl flex={{ base: '1 1 100%', md: '1 1 30%' }}>
                      <Select placeholder="Trạng thái" value={status} onChange={e => setStatus(e.target.value)}>
                        {statusOrderOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      flex={{ base: '1 1 100%', md: '1 1 10%' }}
                      variant="primary"
                      px={10}
                      maxH="40px"
                      alignSelf={'end'}
                      onClick={handleSearch}
                    >
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm đơn hàng
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <ProductOrderTable
              orderData={orderData?.data || []}
              refetch={refetch}
              handleRejectOrder={handleRejectItem}
              handleSuccessOrder={handleSuccessItem}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={orderData?.pagination?.page}
              pageLength={orderData?.pagination?.pageSize}
              totalRecords={orderData?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isCreateModalOpen && (
        <OrderModal orderDetail={orderEditing} isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} modalType={type} />
      )}
    </Flex>
  );
}

export default ProductOrder;
