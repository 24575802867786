import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getCatForProduct = params => request.get('/api/v1/categories', { params });
export const getSizeForProduct = data => request.get('/api/v1/sizes', { params: { categoryId: data.categoryId } });
export const getColorForProduct = data => request.get('/api/v1/colors', { params: { categoryId: data.categoryId } });
export const createProduct = data => request.post('/api/v1/products/create', data);
export const updateProduct = data => request.post('/api/v1/products/update', data);
export const deleteProduct = data => request.post('/api/v1/products/delete', data);
export const createProductWithMember = data => request.post('/api/v1/products/member/create', data);
export const uploadPhotoProduct = (data, config) =>
  request.post('/api/v1/products/upload-thumbnail', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const getProducts = (params, headers) => request.get('/api/v1/products', { params, ...headers });
export const getProductsByMember = (params, headers) => request.get('/api/v1/products/member/get-list', { params, ...headers });
export const getProductDetail = id => request.get(`/api/v1/products/${id}`);
export const changeStatusProduct = data => request.post('/api/v1/products/change-status', data);
export const setNewProduct = data => request.post('/api/v1/products/set-is-new', data);
export const updateProductMember = data => request.post('/api/v1/products/member/update', data);
export const setHotProduct = data => request.post('/api/v1/products/set-is-best', data);
export const changeStatusSoldout = data => request.post('/api/v1/products/change-sold-out', data);
export const submitOrder = data => request.post('/api/v1/orders/submit', data);
export const rejectOrder = data => request.post('/api/v1/orders/reject', data);
export const importExcel = (data, config) =>
  request.post('/api/v1/orders/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const importProduct = (data, config) =>
  request.post('/api/v1/products/import', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });

// Mutation
export const useGetSizeForProdMutation = () => useMutation({ mutationFn: getSizeForProduct });
export const useGetColorForProdMutation = () => useMutation({ mutationFn: getColorForProduct });
export const useCreateProductMutation = () => useMutation({ mutationFn: createProduct });
export const userUpdateProductMemberMutation = () => useMutation({ mutationFn: updateProductMember });
export const useUpdateProductMutation = () => useMutation({ mutationFn: updateProduct });
export const useDeleteProductMutation = () => useMutation({ mutationFn: deleteProduct });
export const useCreateProductWithMemberMutation = () => useMutation({ mutationFn: createProductWithMember });
export const useChangeStatusProductMutation = () => useMutation({ mutationFn: changeStatusProduct });
export const useSetNewProductMutation = () => useMutation({ mutationFn: setNewProduct });
export const useSetHotProductMutation = () => useMutation({ mutationFn: setHotProduct });
export const useChangeStatusSoldoutMutation = () => useMutation({ mutationFn: changeStatusSoldout });
export const useSubmitOrderMutation = () => useMutation({ mutationFn: submitOrder });
export const useRejectOrderMutation = () => useMutation({ mutationFn: rejectOrder });
export const useExportTemplateProductMutation = (params, headers) => useMutation({ mutationFn: () => getProducts(params, headers) });

// Query
export const useQueryGetCatForProduct = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_CATE_FOR_PRODUCT', params], queryFn: () => getCatForProduct(params), ...options });
export const useQueryGetProducts = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_PRODUCTS', params], queryFn: () => getProducts(params), ...options });
export const useQueryGetProductDetail = (id, options = {}) =>
  useQuery({ queryKey: ['GET_PRODUCT_DETAIL', id], queryFn: () => getProductDetail(id), ...options });
export const useQueryGetProductsByMember = (id, options = {}) =>
  useQuery({ queryKey: ['GET_PRODUCT_BY_MEMBER', id], queryFn: () => getProductsByMember(id), ...options });
