import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  Image,
  Input,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import PreOrderTable from './components/Table';
import Danger from 'assets/svg/danger.svg';
import { useQueryGetOrder } from 'services/coupon';

function PreOrder() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [ordeCode, setOrderCode] = useState('');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    type: "PRE_ORDER"
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: orderData, refetch } = useQueryGetOrder({ ...filter, searchKeyword: filter.searchKeyword }, { enabled: isLoggedIn });

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: ordeCode,
    });
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Sản phẩm đặt trước
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack width="100%">
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl flex={{ base: '1 1 100%', md: '1 1 80%' }}>
                      <Input placeholder="Nhập mã đơn hàng" value={ordeCode} onChange={e => setOrderCode(e.target.value)} />
                    </FormControl>
                    <Button
                      flex={{ base: '1 1 100%', md: '1 1 10%' }}
                      variant="primary"
                      px={10}
                      maxH="40px"
                      alignSelf={'end'}
                      onClick={handleSearch}
                    >
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm đơn hàng
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
              <Flex marginTop={42} alignItems={'center'} gap={5} rounded="md" bg="#FFFDF8" border={'1px solid #FFC700'} p="4">
                <Image src={Danger} alt="Danger" w="30px" h="30px" objectFit="contain" />
                <Flex direction="column" gap={3}>
                  <Text fontSize="14px" fontWeight="bold" color="#3A3A3A">
                    Lưu ý
                  </Text>
                  <UnorderedList>
                    <ListItem>
                      Thời gian hoàn thành đơn hàng tính bằng ngày, trong thời gian đó, nếu kho hàng đủ số lượng thì đơn hàng sẽ tự động
                      hoàn thành. Ngược lại đơn sẽ tự hủy và hoàn tiền cho khách.
                    </ListItem>
                    <ListItem>Bạn có thể hủy đơn trước khi làm mới kho nếu thấy giá bán cũ không còn hợp lý.</ListItem>
                  </UnorderedList>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <PreOrderTable
              orderData={orderData?.data || []}
            // refetch={refetch}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={orderData?.pagination?.page}
              pageLength={orderData?.pagination?.pageSize}
              totalRecords={orderData?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default PreOrder;
