import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getCoupon = params => request.get('/api/v1/coupon/list', { params });
export const getRiview = params => request.get('/api/v1/products/list-rate', { params });
export const getMyReports = params => request.get('/api/v1/my-reports', { params });
export const getOrder = params => request.get('/api/v1/orders', { params });
export const createCoupon = data => request.post('/api/v1/coupon/create', data);
export const deleteCoupon = data => request.post('/api/v1/coupon/delete', data);
export const getListSubCategory = data => request.get('/api/v1/sub-categories', data);

// Mutation
export const useCreateCouponMutation = () => useMutation({ mutationFn: createCoupon });
export const useDeleteCouponMutation = () => useMutation({ mutationFn: deleteCoupon });

// Query
export const useQueryGetCoupon = (params = {}, options = {}) =>
    useQuery({ queryKey: ['GET_COUPON', params], queryFn: () => getCoupon(params), ...options });
export const useQueryGetRiview = (params = {}, options = {}) =>
    useQuery({ queryKey: ['GET_REVIEW', params], queryFn: () => getRiview(params), ...options });
export const useQueryGetMyReports = (params = {}, options = {}) =>
    useQuery({ queryKey: ['GET_MY_REPORTS', params], queryFn: () => getMyReports(params), ...options });
export const useQueryGetOrder = (params = {}, options = {}) =>
    useQuery({ queryKey: ['GET_ORDER', params], queryFn: () => getOrder(params), ...options });
export const useQueryGetListSubCategory = (params = {}, options = {}) =>
    useQuery({ queryKey: ['GET_LIST_SUB_CATEGORY', params], queryFn: () => getListSubCategory(params), ...options });