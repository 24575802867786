import { Box, Flex, FormControl, IconButton, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { formatDate } from 'utils/helpers';
import { useDeleteUserMutation, useResetPasswordUserMutation } from 'services/user';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { FaRegFolderOpen } from 'react-icons/fa';
import { useSetHotProductMutation } from 'services/product';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import tick from 'assets/img/tick-circle.png';
import close from 'assets/img/close-circle.png';

const ProductTable = ({ productData, userInfo, refetch, handleUpdateCategory }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteAdminMutation = useDeleteUserMutation();
  const resetPasswordAdminMutation = useResetPasswordUserMutation();
  const setBestProductMutation = useSetHotProductMutation();
  const history = useHistory();

  const handleDeleteSize = async admin => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa tài khoản quản trị này không?');
    if (!confirmDelete) {
      return;
    }
    deleteAdminMutation.mutate(
      { userId: admin?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa tài khoản quản trị thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa tài khoản quản trị không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleBestproduct = async product => {
    const actionText = product.isBest ? 'Xóa' : 'Thêm';
    const confirmMessage = window.confirm(`Bạn có chắc chắn muốn cài đặt sản phẩm ${product.name} là sản phẩm tốt nhất?`);
    if (!confirmMessage) {
      return;
    }
    setBestProductMutation.mutate(
      { id: product._id },
      {
        onSuccess: () => {
          const successMessage = `${actionText} sản phẩm ${product.name} là sản phẩm tốt nhất thành công`;
          toast.showMessageSuccess(successMessage);
          refetch?.();
        },
        onError: () => {
          const errorMessage = `${actionText} sản phẩm ${product.name} là sản phẩm tốt nhất không thành công`;
          toast.showMessageError(errorMessage);
          refetch?.();
        },
      }
    );
  };

  const handleSwitchChange = product => {
    handleBestproduct(product);
  };
  const handleUpdateProduct = product => {
    history.push(`/admin/update/department/${product._id}`);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'RESET_PASSWORD_ADMIN'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleUpdateProduct(info?.row?.original, ModalType.ResetPassword);
                }}
              >
                <EditIcon cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
            {/* <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'DELETE_ADMIN'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleUpdateCategory(info?.row?.original, ModalType.Add);
                }}
              >
                <FaRegFolderOpen color="red.400" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper> */}
          </Flex>
        ),
      }),
      columnHelper.accessor('name', {
        header: 'Tên gian hàng',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('type', {
        header: 'Loại',
        cell: info => {
          const value = info.getValue();
          return value === 'PRODUCT' ? 'Đơn hàng sản phẩm' : value === 'SERVICE' ? 'Đơn hàng dịch vụ' : value;
        },
      }),
      columnHelper.accessor('isReseller', {
        header: 'Reseller',
        cell: info => info.getValue(),
        cell: info => {
          const isBest = info.row.original.isBest;
          const thumbnailUrl = isBest ? tick : close;
          return (
            <img
              src={thumbnailUrl}
              style={{ width: '20px', height: '20px', objectFit: 'contain' }}
            />
          );
        },
      }),
      columnHelper.accessor('price', {
        header: 'Đơn giá',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('isBest', {
        header: 'Nổi bật',
        cell: info => (
          <FormControl display="flex" alignItems="center">
            <Switch isChecked={info.row.original.isBest} onChange={() => handleSwitchChange(info.row.original)} />
          </FormControl>
        ),
      }),
      columnHelper.accessor('totalQuantity', {
        header: 'Tổng số lượng',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('remainingQuantity', {
        header: 'Còn lại',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('exchangedQuantity', {
        header: 'Đã bán',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => {
          const dateValue = new Date(info.getValue());
          const adjustedDate = new Date(dateValue.setHours(dateValue.getHours() + 7));
          return <Text whiteSpace={'nowrap'}>{formatDate(adjustedDate, 'DD/MM/YYYY | hh:mm a')}</Text>;
        },
      }),
    ],
    [productData]
  );

  const table = useReactTable({
    data: productData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id} bg={'#EEF0FF'}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px" py={5}>
                {header.isPlaceholder ? null : (
                  <Box
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                    isTruncated
                    textTransform="capitalize"
                    fontSize={14}
                    fontFamily={'Poppins, sans-serif'}
                    textColor={'#1951EE'}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' ↑',
                      desc: ' ↓',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={11}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default ProductTable;
