import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Image,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { DepartmentFormUpdateValidate, DepartmentFormValidate } from 'utils/validation';
import CheckboxController from 'components/Form/CheckboxController';
import EditorController from 'components/Form/EditorController';
import Danger from 'assets/svg/danger.svg';
import { useQueryGetListCategory, useQueryGetListSubCategory } from 'services/category';
import { TypeBusiness } from 'constants/common';
import { mappingOptionSelect } from 'utils/mapping';
import { uploadPhotoProduct, useCreateProductWithMemberMutation, useQueryGetProductDetail, userUpdateProductMemberMutation } from 'services/product';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'components/Toast';
import { useParams } from 'react-router-dom';

function UpdateDepartment() {
  const history = useHistory();
  const params = useParams();
  const { id: productID } = params || {};
  const [error, setError] = useState({
    thumbnailFile: null,
  });
  const [files, setFiles] = useState([]);
  const [isDragEnter, setIsDragEnter] = useState(false);
  const { data: dataProduct, refetch } = useQueryGetProductDetail(productID)
  console.log('productID', productID);
  console.log('product', dataProduct);


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDragEnter: () => {
      setIsDragEnter(true);
    },
    onDragLeave: () => {
      setIsDragEnter(false);
    },
    onDrop: acceptedFiles => {
      setIsDragEnter(false);
      setError({ thumbnailFile: null });
      setFiles(prev => [
        ...prev,
        ...acceptedFiles.map(file => ({
          id: nanoid(),
          preview: URL.createObjectURL(file),
          file,
        })),
      ]);
    },
  });

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  useEffect(() => {
    if (dataProduct) {
      setValue('name', dataProduct?.data?.name);
      setValue('shortName', dataProduct?.data?.shortName);
      setValue('price', dataProduct?.data?.price);
      setValue('description', dataProduct?.data?.description);
      setValue('categoryId', dataProduct?.data?.categoryId);
      setValue('totalQuantity', dataProduct?.data?.totalQuantity);
      setValue('exchangedQuantity', dataProduct?.data?.exchangedQuantity);
      setValue('productID', dataProduct?.data?._id);
      setValue('isReseller', dataProduct?.data?.isReseller);
    }
  }, [dataProduct])

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError: setErrorField,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    resolver: yupResolver(DepartmentFormUpdateValidate),
    defaultValues: {},
  });
  const updateProductWithMemberMutation = userUpdateProductMemberMutation()

  const onRemove = index => {
    setFiles(prev => prev.filter((i, idX) => idX !== index));
  };

  const onUploadPhoto = async () => {
    const formData = new FormData();
    const filesUpload = files?.filter(item => item.file);
    let pathFiles = [];

    if (!isEmpty(filesUpload)) {
      filesUpload.map(item => {
        formData.append('productFile', item.file);
      });
      const response = await uploadPhotoProduct(formData);

      pathFiles = response?.data;

      return pathFiles;
    }
    return null;
  };

  const onSubmit = async values => {
    const photosPath = await onUploadPhoto();
    const newDataForm = {
      name: values.name,
      shortName: values.shortName,
      price: values.price,
      description: values.description,
      thumbnail: photosPath || dataProduct?.data?.thumbnail,
      categoryId: values.categoryId?._id,
      totalQuantity: values.totalQuantity,
      exchangedQuantity: values.exchangedQuantity,
      productID: dataProduct?.data?._id,
    };
    updateProductWithMemberMutation.mutate(
      newDataForm,
      {
        onSuccess: () => {
          toast.showMessageSuccess('Cập nhật sản phẩm thành công');
          history.push('/admin/department');
        },
        onError: error => {
          toast.showMessageError('Cập nhật sản phẩm thất bại');
        },
      }
    )
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" fontWeight={600} fontSize={20}>
          Cập nhật gian hàng
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <form>
              <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }} mb={3}>
                <InputController control={control} name="name" placeholder="Tên gian hàng" isRequired styleContainer={{ pt: '4' }} />
              </Flex>
              <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                <SelectController
                  styleContainer={{ pt: '4' }}
                  control={control}
                  isRequired
                  name="typeSell"
                  placeholder="Loại gian hàng"
                  isClearable
                  isDisabled
                  value={
                    dataProduct?.data?.categoryId
                      ? {
                        label: dataProduct?.data?.categoryId?.title,
                        value: dataProduct?.data?.categoryId?._id,
                      }
                      : null
                  }
                />
                <SelectController
                  styleContainer={{ pt: '4' }}
                  control={control}
                  isRequired
                  name="typeProduct"
                  placeholder="Loại sản phẩm - Chiết khấu cho sàn"
                  isClearable
                  isDisabled
                  value={
                    dataProduct?.data?.categoryId
                      ? {
                        label: dataProduct?.data?.subCategory?.title,
                        value: dataProduct?.data?.subCategory?._id,
                      }
                      : null
                  }
                />
              </Flex>
              <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }} mb={3}>
                <InputController control={control} name="price" placeholder="Giá Bán" isRequired styleContainer={{ pt: '4' }} />
                <InputController control={control} name="totalQuantity" placeholder="Số lượng" isRequired styleContainer={{ pt: '4' }} />
              </Flex>
              <CheckboxController
                label="Bạn có muốn cho reseller bán hàng không?"
                name={'isReseller'}
                control={control}
                options={[]}
                styleContainer={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  gap: 3,
                  alignItems: 'center',
                  justifyContent: 'revert',
                  pb: '4',
                  width: 'fit-content',
                }}
              />
              <InputController
                isRequired
                control={control}
                name="shortName"
                label="Mô tả ngắn"
                placeholder="Nhập mô tả"
                styleContainer={{ pt: '4' }}
              />
              <EditorController
                styleContainer={{ pt: '4' }}
                control={control}
                name="description"
                label="Nội dung bài viết"
                isRequired
              // uploadApiUrl={API_ROUTES.UPLOAD_IMAGE}
              />
              <FormControl pt={4}>
                <FormLabel minW="150px">Ảnh</FormLabel>
                <Box
                  {...getRootProps()}
                  cursor="pointer"
                  borderWidth="1px"
                  borderStyle="dashed"
                  borderRadius="12px"
                  padding="55px"
                  textAlign="center"
                  height="210px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <input {...getInputProps()} />
                  <BsUpload size={40} />
                  {isDragEnter ? (
                    <p>Thả ảnh của bạn</p>
                  ) : (
                    <>
                      <p>
                        <Text as="span" color="red.400">
                          Tải tập tin lên
                        </Text>
                        &nbsp;hoặc kéo và thả
                      </p>
                    </>
                  )}
                  <Text fontSize="12px" color="gray.500" pt={1}>
                    Kích thước lớn hơn 320px
                  </Text>
                </Box>


                <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                  {files?.map((file, index) => (
                    <GridItem w={'100px'} key={file.id} position="relative">
                      <Image src={file.preview} w="100px" h="100px" objectFit="contain" />
                      <IconButton bg="transparent" position="absolute" top="0" right="0" onClick={() => onRemove(index)}>
                        <BsXCircle size={16} color="red" />
                      </IconButton>
                    </GridItem>
                  ))}
                </Grid>
                {!!error.thumbnailFile && (
                  <Text pt={1} color={'red.500'} fontSize="13px">
                    {error.thumbnailFile}
                  </Text>
                )}
              </FormControl>
            </form>

            <Flex w={'100%'} pt={6} alignItems="flex-end" justifyContent="flex-end">
              <Button
                w={'100%'}
                onClick={() => {
                  history.goBack();
                }}
              >
                Quay lại
              </Button>
              <Button
                w={'100%'}
                colorScheme="blue"
                ml={3}
                // isLoading={createProductMutation.isPending || updateProductMutation.isPending}
                onClick={handleSubmit(onSubmit)}
              >
                {'Cập nhật'}
              </Button>
            </Flex>
          </Stack>
        </CardBody>
      </Card>
      {/* {isCreateModalOpen && (
          <CreateCategoryModal userDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />
        )}
        {isUpdatePasswordModalOpen && (
          <UpdatePassword userDetail={sizeEditing} isOpen={isUpdatePasswordModalOpen} onClose={handelCloseModal} />
        )}
        {isAssignPermissionModalOpen && (
          <AssignPermission userDetail={sizeEditing} isOpen={isAssignPermissionModalOpen} onClose={handelCloseModal} refetch={refetch} />
        )} */}
    </Flex>
  );
}

export default UpdateDepartment;
