import moment from 'moment-timezone';
import accounting from 'accounting';

export const isJsonString = str => {
  if (str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export function rangeDesc(start, end) {
  return Array(start - end + 1)
    .fill()
    .map((_, idx) => start - idx);
}

export const today = moment().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD');

export const formatDate = (date, format = 'MM/DD/YYYY') => moment.utc(date).format(format);
export const formatDateVN = (date, format = 'DD/MM/YYYY hh:mm:ss') => {
  // Cộng 7 giờ vào date
  const newDate = moment.utc(date).add(7, 'hours');
  return newDate.format(format);
};
export const getInitFilerChart = () => ({
  startDate: new Date(formatDate(moment(new Date()).subtract(6, 'days').add(1, 'day'))),
  endDate: new Date(formatDate(moment(new Date()).add(1, 'day'))),
});

export const downloadFile = (file, name) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getDomainUrl = (url) => {
  const matchResult = url?.match(/^https?:\/\/([a-zA-Z0-9.-]+)\.com$/);
  if (matchResult && matchResult.length === 2) {
    return matchResult[1];
  }
}

export const getUrlWebsite = (domain) => {
  return 'https://' + domain + '.com'
}

export const isObjectNameInArray = (targetName, array, key) => array?.some(element => element[key] === targetName);

export const convertToTitleCase = (str) => {
  const words = str.split('_');
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  const convertedStr = capitalizedWords.join(' ');
  return convertedStr;
};
export function formatNumber(value, decimal = 2) {
  return accounting.formatNumber(value, decimal, '').replace(/.00$/g, '');
}

export function formatCurrencyToVND(amount) {
  if (isNaN(amount)) {
    return '0 xu';
  }

  const formattedAmount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return `${formattedAmount} xu`;
}

export function generateDayWiseTimeSeries(baseval, count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = baseval;
    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push([x, y]);
    baseval += 86400000;
    i++;
  }
  return series;
}

export const convertMMYYYYToMonth = (dateString) => {
  const parts = dateString.split('/');
  if (parts.length === 2) {
    const month = parseInt(parts[0], 10);
    return month;
  } else {
    throw new Error('Invalid date format. Expected format is MM/YYYY');
  }
};

export const convertToMMYYYY = input => {
  let date;
  if (typeof input === 'number') {
    date = new Date(input);
  } else if (input instanceof Date) {
    date = input;
  } else {
    return new Date(input);
  }

  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  return `${month}/${year}`;
};
