import React, { useEffect, useState } from 'react';
import { Box, Flex, Button, FormControl, FormLabel, useColorModeValue, Text, Link } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { useUserDispatch, loginUser } from 'context/UserContext';
import { CookieStorage } from 'utils/cookie-storage';
import InputController from '../../components/Form/InputController';
import { LoginFormValidate, ResetPasswordValidate, SetNewPasswordValidate } from '../../utils/validation';
import { useLoginMemberMutation, useLoginMutation, useSentOtpEmailResetPasswordMemberMutation, useSetNewPasswordMutation } from '../../services/user';
import SignInImage from 'assets/img/signInImage.png';
import { BO_URL, StatusLogin } from 'constants/common';
import isEmpty from 'lodash/isEmpty';
import { FaQuestion } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { toast } from 'components/Toast';

function SignIn() {
  const [show, setShow] = useState({
    password: false,
  });
  const bgForm = useColorModeValue('white', 'navy.800');
  const userDispatch = useUserDispatch();
  const history = useHistory();
  const loginMemberMutation = useLoginMemberMutation();
  const sentOtpEmailResetPasswordMemberMutation = useSentOtpEmailResetPasswordMemberMutation();
  const setNewPasswordMutation = useSetNewPasswordMutation();
  const isLoggedIn = CookieStorage.isAuthenticated();
  const [emailReset, setEmailReset] = useState('');
  const [status, setStatus] = useState(StatusLogin.LOGIN);
  const loginMutation = useLoginMutation();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(
      status === StatusLogin.LOGIN ? LoginFormValidate : status === StatusLogin.SENT_MAIL ? ResetPasswordValidate : SetNewPasswordValidate
    ),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      return history.push('/admin');
    }
  }, [isLoggedIn]);

  const onSubmit = values => {
    if (status === StatusLogin.SENT_MAIL) {
      sentOtpEmailResetPasswordMemberMutation.mutate(
        {
          email: values.email,
        },
        {
          onSuccess: () => {
            reset({
              password: null,
            });
            setStatus(StatusLogin.SET_NEW);
            toast.showMessageSuccess('Gửi otp code đến email thành công.');
          },
        }
      );
      return;
    }
    if (status === StatusLogin.SET_NEW) {
      setNewPasswordMutation.mutate(
        {
          password: values.password,
          otp: values.otp,
        },
        {
          onSuccess: () => {
            reset({
              password: null,
            });
            setStatus(StatusLogin.LOGIN);
            toast.showMessageSuccess('Cài lại mật khẩu thành công.');
          },
        }
      );
      return;
    }
    (loginMemberMutation).mutate(
      { ...values },
      {
        onSuccess: response => {
          const { data, token, memberInfo } = response || {};
          loginUser(userDispatch, token, data || memberInfo, !isEmpty(memberInfo));
          history.push('/admin/dashboard');
        },
      }
    );
  };

  return (
    <>
      {status === StatusLogin.LOGIN && (
        <Flex position="relative">
          <Flex
            minH={{ md: '1000px' }}
            h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
            w="100%"
            maxW="1044px"
            mx="auto"
            justifyContent="space-between"
            mb="30px"
            pt={{ md: '0px' }}
          >
            <Flex w="100%" h="100%" alignItems="center" justifyContent="center" mb="60px" mt={{ base: '50px', md: '20px' }}>
              <Flex
                zIndex="2"
                direction="column"
                w="445px"
                background="transparent"
                borderRadius="15px"
                p="40px"
                mx={{ base: '100px' }}
                m={{ base: '20px', md: 'auto' }}
                bg={bgForm}
                boxShadow={useColorModeValue('0px 5px 14px rgba(0, 0, 0, 0.05)', 'unset')}
              >
                <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} mb={4}>
                  <Text fontWeight={600} fontSize={20}>
                    ĐĂNG NHẬP VnOTP
                  </Text>
                  <Text fontSize={13}>
                    Chưa có tài khoản?{' '}
                    <Link
                      href={'/#/auth/sign-up'}
                      textColor="blue.300"
                      _hover={{
                        textColor: 'blue.500',
                      }}
                    >
                      Đăng ký
                    </Link>
                  </Text>
                </Flex>
                <FormControl>
                  <form>
                    <InputController
                      control={control}
                      name="username"
                      label="Tên đăng nhập"
                      isRequired
                      styleContainer={{ marginBottom: '15px' }}
                      styleBoxInput={{ flex: 1 }}
                    />
                    <InputController
                      control={control}
                      type={show.password ? 'text' : 'password'}
                      name="password"
                      label="Mật khẩu"
                      isRequired
                      styleContainer={{ marginBottom: '15px' }}
                      styleBoxInput={{ flex: 1 }}
                      inputRightElement={
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() =>
                            setShow({
                              ...show,
                              password: !show.password,
                            })
                          }
                        >
                          {show.password ? 'Hide' : 'Show'}
                        </Button>
                      }
                    />
                  </form>
                  <FormControl display="flex" alignItems="center" justifyContent={'end'} mb="24px">
                    <FormLabel
                      onClick={() => setStatus(StatusLogin.SENT_MAIL)}
                      _hover={{
                        textUnderlineOffset: '2px',
                        color: 'blue.500',
                      }}
                      display={'flex'}
                      justifyContent={'end'}
                      alignItems={'start'}
                      gap={1}
                      color="#1A202C"
                      cursor={'pointer'}
                      htmlFor="remember-login"
                      mb="0"
                      fontWeight="bold"
                      fontSize={12}
                    >
                      <Text>Quên mật khẩu</Text> <FaQuestion />
                    </FormLabel>
                  </FormControl>
                  <Button onClick={handleSubmit(onSubmit)} fontSize="10px" variant="dark" fontWeight="bold" w="100%" h="45" mb="24px">
                    Đăng nhập
                  </Button>
                </FormControl>
              </Flex>
            </Flex>
            <Box overflowX="hidden" h="100%" w="100%" left="0px" position="absolute" bgImage={SignInImage}>
              <Box w="100%" h="100%" bgSize="cover" bg="blue.500" opacity="0.8"></Box>
            </Box>
          </Flex>
        </Flex>
      )}
      {status === StatusLogin.SENT_MAIL && (
        <Flex position="relative">
          <Flex
            minH={{ md: '1000px' }}
            h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
            w="100%"
            maxW="1044px"
            mx="auto"
            justifyContent="space-between"
            mb="30px"
            pt={{ md: '0px' }}
          >
            <Flex w="100%" h="100%" alignItems="center" justifyContent="center" mb="60px" mt={{ base: '50px', md: '20px' }}>
              <Flex
                zIndex="2"
                direction="column"
                w="445px"
                background="transparent"
                borderRadius="15px"
                p="40px"
                mx={{ base: '100px' }}
                m={{ base: '20px', md: 'auto' }}
                bg={bgForm}
                boxShadow={useColorModeValue('0px 5px 14px rgba(0, 0, 0, 0.05)', 'unset')}
              >
                <Flex alignItems={'center'} mb={4}>
                  <FormLabel
                    onClick={() => {
                      setStatus(StatusLogin.LOGIN);
                    }}
                    _hover={{
                      textUnderlineOffset: '2px',
                      color: 'blue.500',
                    }}
                    display={'flex'}
                    justifyContent={'end'}
                    alignItems={'center'}
                    gap={1}
                    color="#1A202C"
                    cursor={'pointer'}
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="bold"
                    fontSize={12}
                  >
                    <MdArrowBack />
                    <Text>Đăng nhập</Text>
                  </FormLabel>
                  <Text flex={1} ml={'-20px'} textAlign={'center'} fontWeight={600} fontSize={20}>
                    Quên mật khẩu
                  </Text>
                </Flex>
                <FormControl>
                  <form>
                    <InputController
                      onChange={e => setEmailReset(e.target.value)}
                      control={control}
                      name="email"
                      label="Email"
                      isRequired
                      styleContainer={{ marginBottom: '15px' }}
                    />
                  </form>
                  <Button onClick={handleSubmit(onSubmit)} fontSize="10px" variant="dark" fontWeight="bold" w="100%" h="45" mb="24px">
                    Gửi yêu cầu
                  </Button>
                </FormControl>
              </Flex>
            </Flex>
            <Box overflowX="hidden" h="100%" w="100%" left="0px" position="absolute" bgImage={SignInImage}>
              <Box w="100%" h="100%" bgSize="cover" bg="blue.500" opacity="0.8"></Box>
            </Box>
          </Flex>
        </Flex>
      )}
      {status === StatusLogin.SET_NEW && (
        <Flex position="relative">
          <Flex
            minH={{ md: '1000px' }}
            h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
            w="100%"
            maxW="1044px"
            mx="auto"
            justifyContent="space-between"
            mb="30px"
            pt={{ md: '0px' }}
          >
            <Flex w="100%" h="100%" alignItems="center" justifyContent="center" mb="60px" mt={{ base: '50px', md: '20px' }}>
              <Flex
                zIndex="2"
                direction="column"
                w="445px"
                background="transparent"
                borderRadius="15px"
                p="40px"
                mx={{ base: '100px' }}
                m={{ base: '20px', md: 'auto' }}
                bg={bgForm}
                boxShadow={useColorModeValue('0px 5px 14px rgba(0, 0, 0, 0.05)', 'unset')}
              >
                <Flex alignItems={'center'} mb={4}>
                  <FormLabel
                    onClick={() => {
                      setStatus(StatusLogin.LOGIN);
                    }}
                    _hover={{
                      textUnderlineOffset: '2px',
                      color: 'blue.500',
                    }}
                    display={'flex'}
                    justifyContent={'end'}
                    alignItems={'center'}
                    gap={1}
                    color="#1A202C"
                    cursor={'pointer'}
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="bold"
                    fontSize={12}
                  >
                    <MdArrowBack />
                    <Text>Đăng nhập</Text>
                  </FormLabel>
                  <Text flex={1} ml={'-20px'} textAlign={'center'} fontWeight={600} fontSize={20}>
                    Quên mật khẩu
                  </Text>
                </Flex>
                <Flex flexDirection={'column'} gap={10}>
                  <Text>
                    Hệ thống đã gửi tới email <Text fontWeight={600}>{emailReset}</Text> otp code.
                    <br />
                    Vui lòng kiểm tra email để cài mật khẩu mới
                  </Text>
                  <FormControl>
                    <form>
                      <InputController
                        control={control}
                        type={show.password ? 'text' : 'password'}
                        name="password"
                        label="Mật khẩu mới"
                        isRequired
                        styleContainer={{ marginBottom: '15px' }}
                        styleBoxInput={{ flex: 1 }}
                        inputRightElement={
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={() =>
                              setShow({
                                ...show,
                                password: !show.password,
                              })
                            }
                          >
                            {show.password ? 'Hide' : 'Show'}
                          </Button>
                        }
                      />
                      <InputController control={control} name="otp" label="OTP code" isRequired styleContainer={{ marginBottom: '15px' }} />
                    </form>
                    <Button onClick={handleSubmit(onSubmit)} fontSize="10px" variant="dark" fontWeight="bold" w="100%" h="45" mb="24px">
                      Luư
                    </Button>
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
            <Box overflowX="hidden" h="100%" w="100%" left="0px" position="absolute" bgImage={SignInImage}>
              <Box w="100%" h="100%" bgSize="cover" bg="blue.500" opacity="0.8"></Box>
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
}

export default SignIn;
