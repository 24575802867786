import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getReseller = params => request.get('/api/v1/reseller/list', { params });
export const getProfileStore = () => request.get('/api/v1/stores/profile');

// Query
export const useQueryGetReseller = (params = {}, options = {}) =>
    useQuery({ queryKey: ['GET_RESELLER', params], queryFn: () => getReseller(params), ...options });
export const useQueryGetProfileStore = (params = {}, options = {}) =>
    useQuery({ queryKey: ['GET_PROFILE_STORE', params], queryFn: () => getProfileStore(params), ...options });
