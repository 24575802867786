import { Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'components/DatePicker/DatePicker';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { toast } from 'components/Toast';
import { COUPON_TYPE } from 'constants/common';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useCreateCouponMutation } from 'services/coupon';
import { getInitFilerChart } from 'utils/helpers';
import { CouponFormValidate } from 'utils/validation';


const initFiler = {
  endTime: getInitFilerChart().endDate,
  startTime: getInitFilerChart().startDate,
};
function CreateCoupon() {
  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');
  const [statisticFilter, setStatisticFilter] = useState(initFiler);
  const createCouponMutation = useCreateCouponMutation()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setError: setErrorField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CouponFormValidate),
    defaultValues: {
      code: '',
      type: '',
      amount: null
    },
  });
  const selectedType = watch('type');

  const onChangeDate = type => date => {
    const utcDate = moment(date).utc().startOf('day');

    setStatisticFilter(prev => ({
      ...prev,
      ...(type === 'startTime' && { endTime: new Date(utcDate.clone().add(6, 'days')) }),
      [type]: new Date(utcDate),
    }));
  };

  const onSubmit = values => {
    const formattedDateStart = moment(statisticFilter?.startTime).utc().format('YYYY-MM-DD');
    const formattedDateEnd = moment(statisticFilter?.endTime).utc().format('YYYY-MM-DD');
    const amount = values.type.value === COUPON_TYPE.PERCENTAGE ? values.amount : undefined;
    createCouponMutation.mutate(
      {
        ...values,
        dateStart: formattedDateStart,
        dateEnd: formattedDateEnd,
        type: selectedType?.value,
        amount
      },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Tạo mã giảm giá thành công');
          history.push('/admin/coupon');
        },
        onError: error => {
          toast.showMessageError('Tạo mã giảm giá thất bại');
        },
      }
    )
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Thêm mã giảm giá
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <form>
              <Stack gap={{ md: 10, base: 4 }}>
                <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                  <InputController control={control} name="code" placeholder="Nhập mã giảm giá" isRequired />
                </Flex>
                <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                  <FormControl display="flex" flexDirection={'column'} width='full'>
                    <FormLabel m="0" fontWeight={"600"}>Ngày bắt đầu</FormLabel>
                    <DatePicker selectedDate={statisticFilter.startTime} onChange={date => onChangeDate('startTime')(date)} />
                  </FormControl>

                  <FormControl display="flex" flexDirection={'column'} width='full' >
                    <FormLabel m="0" fontWeight={"600"}>Ngày kết thúc</FormLabel>
                    <DatePicker
                      selectedDate={statisticFilter.endTime}
                      minDate={statisticFilter.startTime}
                      onChange={date => onChangeDate('endTime')(date)}
                    />
                  </FormControl>

                </Flex>
                <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                  <SelectController
                    control={control}
                    isRequired
                    name="type"
                    placeholder="Loại giảm giá"
                    isClearable
                    options={[
                      { value: COUPON_TYPE.PERCENTAGE, label: 'Giảm theo phần trăm' },
                      { value: COUPON_TYPE.AMOUNT, label: 'Giảm theo số tiền' }
                    ]}
                  />
                  <InputController control={control} name="max" placeholder="Nhập số tiền tối đa" isRequired />
                  {selectedType?.value === COUPON_TYPE.PERCENTAGE && (
                    <InputController control={control} name="amount" placeholder="Nhập tỉ lệ giảm" isRequired />
                  )}

                  <InputController control={control} name="quantityUsing" placeholder="Nhập số lần sử dụng" isRequired />
                </Flex>
                <InputController
                  control={control}
                  name="description"
                  label="Nhập mô tả"
                  placeholder="Nhập mô tả"
                  styleContainer={{ pt: '4' }}
                />
              </Stack>
              <Flex w={'100%'} pt={{ md: 52, base: 10 }} alignItems="flex-end" justifyContent="flex-end">
                <Button
                  w={'100%'}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Quay lại
                </Button>
                <Button
                  w={'100%'}
                  colorScheme="blue"
                  ml={3}
                  isLoading={createCouponMutation.isPending}
                  onClick={handleSubmit(onSubmit)}
                >
                  {'Tạo mới'}
                </Button>
              </Flex>
            </form>
          </Stack>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default CreateCoupon;
