import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import Danger from 'assets/svg/danger.svg';
import ComplaintTable from './components/Table';
import { useQueryGetMyReports } from 'services/coupon';

function Complaint() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: reportsData, refetch } = useQueryGetMyReports({ ...filter }, { enabled: isLoggedIn });

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Đơn hàng khiếu nại
                </Text>
              </Flex>
              <Flex marginTop={42} alignItems={'center'} gap={5} rounded="md" bg="#FFFDF8" border={'1px solid #FFC700'} p="4">
                <Image src={Danger} alt="Danger" w="30px" h="30px" objectFit="contain" />
                <Flex direction="column" gap={3}>
                  <Text fontSize="14px" fontWeight="bold" color="#3A3A3A">
                    Lưu ý
                  </Text>
                  <UnorderedList>
                    <ListItem>
                      Đơn khiếu nại sẽ có 2 trạng thái. 1.Khiếu nại: Khách hàng khiếu nại về đơn hàng của bạn. 2.Tranh chấp: Bạn không đồng
                      ý với khiếu nại trên và chủ động chuyển sang trạng thái 2 bên tranh chấp.
                    </ListItem>
                    <ListItem>
                      Khi đơn hàng ở trạng thái "Khiếu nại", nếu trong vòng 72h, khách không hủy khiếu nại, hoặc bạn không chủ động chuyển
                      sang tranh chấp, thì đơn hàng sẽ tự động hoàn tiền.
                    </ListItem>
                    <ListItem>Đa phần khách khiếu nại là đợi các bạn bảo hành, nên hãy chủ động liên hệ và xử lý cho khách nhé.</ListItem>
                    <ListItem>
                      Nếu tỷ lệ khiếu nại quá cao (so với tổng số đơn hàng), hệ thống sẽ tự động đóng gian hàng, các bạn hãy kiểm tra lại
                      hàng trước khi mở bán lại nhé.
                    </ListItem>
                  </UnorderedList>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <ComplaintTable
              reportsData={reportsData?.data || []}
            // refetch={refetch}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={reportsData?.pagination?.page}
              pageLength={reportsData?.pagination?.pageSize}
              totalRecords={reportsData?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Complaint;
