import capitalize from 'lodash/capitalize';
import { convertToTitleCase } from 'utils/helpers';

export const ROOT_API = process.env.REACT_APP_API_HOST;
export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;
export const ROOT_APP = process.env.REACT_APP_HOST;
export const ADVERTISER_PAGE_URL = process.env.REACT_APP_ADVERTISER_URL;
export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const BO_URL = process.env.REACT_APP_BO_URL;

export const MAX_PHONE_NUMBER_DIGIT = 10;
export const defaultPassword = 'CrmEmail2023@';
export const MAX_VIDEO_UPLOAD = 1024 * 1024 * 30;
export const Roles = {
  ADMIN: 'admin',
  GUEST: 'guest',
  USER: 'user',
  MANAGER: 'manager',
};

export const StatusLogin = {
  LOGIN: 'login',
  SENT_MAIL: 'sent_mail',
  SET_NEW: 'set_new',
};

export const ToastStatus = {
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
};

export const EMAIL_REGEX = /^([a-zA-Z0-9])+(([a-zA-Z0-9_.-])*)+([a-zA-Z0-9])@(([a-zA-Z0-9-])+([a-zA-Z0-9])+\.)([a-zA-Z]{2,})((\.+([a-zA-Z]{2,}))*)$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
export const PHONE_REGEX = /^(0|84|\+84|084)\d{9}$/;
export const LINK_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const KEY_REGEX = /_/g;
export const CONVERT_UPPERCASE_REGEX = /\b\w/g;
export const URL_REGEX = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const FORMAT_DATE = 'DD-MM-YYYY HH:mm:ss';

export const URL_LANDING_PAGE = 'https://www.vnotp.com';

export const ContactChannelKey = {
  Facebook: 'facebook',
  Telegram: 'telegram',
  Skype: 'skype',
};

export const ContactChannelOptions = Object.keys(ContactChannelKey).map(key => ({
  label: key,
  value: ContactChannelKey[key],
}));

export const AVAILABLE_AD_UNITS = {
  POP_UNDER: 'POP_UNDER',
  BANNER_300_250: 'BANNER_300_250',
  BANNER_320_50: 'BANNER_320_50',
  BANNER_300_600: 'BANNER_300_600',
  BANNER_160_600: 'BANNER_160_600',
  BANNER_970_90: 'BANNER_970_90',
  BANNER_640_320: 'BANNER_640_320',
  BANNER_320_100: 'BANNER_320_100',
  NATIVE_BANNER: 'NATIVE_BANNER',
  DIRECT_LINK: 'DIRECT_LINK',
  SOCIAL_BAR: 'SOCIAL_BAR',
};

const BANNER_SIZE = {
  BANNER_300_250: '300x250',
  BANNER_320_50: '320x50',
  BANNER_300_600: '300x600',
  BANNER_160_600: '160x600',
  BANNER_970_90: '970x90',
  BANNER_640_320: '640x320',
  BANNER_320_100: '320x100',
};

const FONT_SIZE = {
  inherit: 'inherit',
  9: '9px',
  10: '10px',
  11: '11px',
  12: '12px',
  13: '13px',
  14: '14px',
  15: '15px',
  16: '16px',
  17: '17px',
  18: '18px',
  19: '19px',
  20: '20px',
};

const WIDGET_LAYOUT = {
  '1:1': '1:1',
  '1:2': '1:2',
  '1:3': '1:3',
  '1:4': '1:4',
  '2:1': '2:1',
  '2:2': '2:2',
  '3:1': '3:1',
  '4:1': '4:1',
};

export const BannerSizeOptions = Object.keys(BANNER_SIZE).map(key => ({
  label: key,
  value: BANNER_SIZE[key],
}));

export const FontSizeOptions = Object.keys(FONT_SIZE).map(key => ({
  label: FONT_SIZE[key],
  value: FONT_SIZE[key],
}));

export const WidgetLayoutOptions = Object.keys(WIDGET_LAYOUT).map(key => ({
  label: key,
  value: WIDGET_LAYOUT[key],
}));

export const AdUnitsOptions = Object.keys(AVAILABLE_AD_UNITS).map(key => ({
  label: key,
  value: AVAILABLE_AD_UNITS[key],
}));

const DIRECT_LINK_CATEGORY = {
  ADULT: 'Adult',
  NON_ADULT: 'Non-adult',
};

export const DirectLinkCategoryOptions = Object.keys(DIRECT_LINK_CATEGORY).map(key => ({
  label: DIRECT_LINK_CATEGORY[key],
  value: key,
}));

const REMOVE_WEBSITE_WITH = {
  EROTIC_ADS: 'EROTIC_ADS',
  SOFTWARE_ADS: 'SOFTWARE_ADS',
  ALERT_ADS: 'ALERT_ADS',
  ADS_WITH_SOUND: 'ADS_WITH_SOUND',
  GAMBLING_ADS: 'GAMBLING_ADS',
};

export const convertEnumToLabelOption = target => {
  return target
    .replace(KEY_REGEX, ' ')
    .toLowerCase()
    .replace(CONVERT_UPPERCASE_REGEX, l => l.toUpperCase());
};

export const RemoveWebsiteOptions = Object.keys(REMOVE_WEBSITE_WITH).map(key => ({
  label: convertEnumToLabelOption(key),
  value: REMOVE_WEBSITE_WITH[key],
}));

export const CountryKey = {
  Vietnam: 'VN',
  Thailan: 'TH',
  Other: 'other',
};

export const CountryOptions = [
  {
    label: 'Việt Nam',
    value: CountryKey.Vietnam,
  },
  {
    label: 'Thái Lan',
    value: CountryKey.Thailan,
  },
  {
    label: 'Khác',
    value: CountryKey.Other,
  },
];

export const TYPE_TICKET = {
  GENERAL_QUESTION: 'GENERAL_QUESTION',
  MY_ACCOUNT: 'MY_ACCOUNT',
  PAYMENTS: 'PAYMENTS',
  REQUEST_MANAGER: 'REQUEST_MANAGER',
  VISA_MASTERCARD: 'VISA_MASTERCARD',
};

export const TypeTicket = Object.keys(TYPE_TICKET).map(key => ({
  label: convertEnumToLabelOption(key),
  value: TYPE_TICKET[key],
}));

export const TYPE_ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DETAIL: 'DETAIL',
};

export const TabFeatureProfile = {
  IdentityInfo: 'Identity Info',
  ChangePassword: 'Change Password',
};

export const IS_VERIFIED_INFO = {
  PENDING: 'PENDING',
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const DIRECTION = {
  VERTICAL: 'column',
  HORIZONTAL: 'row',
};

export const GROUP_BY_WEBSITE_ADS = {
  DATE: 'DATE',
  PLACEMENT: 'PLACEMENT',
  COUNTRY: 'COUNTRY',
  DEVICE: 'DEVICE',
  DOMAIN: 'DOMAIN',
  SYSTEM: 'SYSTEM',
};

export const GroupByOptions = Object.keys(GROUP_BY_WEBSITE_ADS).map(key => ({
  label: key,
  value: GROUP_BY_WEBSITE_ADS[key],
}));

export const ModalType = {
  Add: 'add',
  Delete: 'delete',
  ChangeStatus: 'changeStatus',
  EmailBackup: 'emailBackup',
  Import: 'import',
  Preview: 'preview',
  Detail: 'detail',
  ResetPassword: 'resetPassword',
  Assign: 'assign',
  Block: 'block',
  Adjust: 'adjust',
  Reject: 'reject',
  Success: 'success',
};

export const STATUS_PURCHASE_HISTORY = {
  complete: 'complete',
  expired: 'expired',
  open: 'open',
};

export const StatusPurchaseHistoryOptions = Object.keys(STATUS_PURCHASE_HISTORY).map(key => ({
  label: key,
  value: STATUS_PURCHASE_HISTORY[key],
}));

export const FileImageValid = ['png', 'jpeg', 'jpg'];

export const FileExcelValid = ['xls', 'xlsx'];

export const AddressType = {
  RETURN_ADDRESS: 'Địa chỉ hoàn trả',
  SOURCE_ADDRESS: 'Địa chỉ gửi',
};

export const AddressTypeOption = Object.keys(AddressType).map(key => ({
  label: AddressType[key],
  value: key,
}));

export const ORDER_STATUS = {
  WAITING_TO_CONFIRM: 'Đang chờ xác nhận',
  REJECTED: 'Đã hủy',
  PROCESSING: 'Đang xử lý',
  APPROVED: 'Đã duyệt',
  SHIPPING: 'Đang giao',
  SUCCESS: 'Giao thành công',
};

export const OrderStatusOption = Object.keys(ORDER_STATUS).map(key => ({
  label: capitalize(ORDER_STATUS[key]),
  value: key,
}));

export const MISSION_TYPE = {
  RECHARGE_IN_DAY: 'RECHARGE_IN_DAY',
  RECHARGE_IN_WEEK: 'RECHARGE_IN_WEEK',
  BET_IN_DAY: 'BET_IN_DAY',
  BET_IN_WEEK: 'BET_IN_WEEK',
};

export const MISSION_TYPE_SUB = {
  RECHARGE_IN_DAY: 'Nạp trong ngày',
  RECHARGE_IN_WEEK: 'Nạp trong tuần',
  BET_IN_DAY: 'Cược trong ngày',
  BET_IN_WEEK: 'Cược trong tuần',
};

export const MISSION_BO_TYPE = {
  RECHARGE: 'RECHARGE',
  BET: 'BET',
};

export const MissionBoOption = Object.keys(MISSION_BO_TYPE).map(key => ({
  label: capitalize(key),
  value: MISSION_BO_TYPE[key],
}));

export const MissionOption = Object.keys(MISSION_TYPE_SUB).map(key => ({
  label: capitalize(convertToTitleCase(MISSION_TYPE_SUB[key])),
  value: key,
}));

export const BRAND = {
  '2Q': '2Q',
  '888B': '888B',
  '8DAY': '8DAY',
  STORE: 'STORE',
};

export const BrandFilter = Object.keys(BRAND).map(key => ({
  label: BRAND[key],
  value: key,
}));

const LEVEL_MEMBER = {
  VIP0: 'NORMAL',
  VIP1: 'COPPER',
  VIP2: 'SILVER',
  VIP3: 'GOLD',
  VIP4: 'DIAMOND',
};

export const LevelMemberKkOptionFilter = Object.keys(LEVEL_MEMBER).map(key => ({
  label: capitalize(LEVEL_MEMBER[key]),
  value: LEVEL_MEMBER[key],
}));

export const LevelMemberOptionFilter = Object.keys(LEVEL_MEMBER).map(key => ({
  label: capitalize(LEVEL_MEMBER[key]),
  value: key,
}));

export const LevelMemberOption = Object.keys(LEVEL_MEMBER).map(key => ({
  label: capitalize(LEVEL_MEMBER[key]),
  value: LEVEL_MEMBER[key],
}));

export const SystemNotiType = {
  SYSTEM: 'Hệ thống',
  // UPDATE_PRODUCT: "Cập nhật sản phẩm",
  UPDATE_ENDOW: 'Cập nhật ưu đãi',
};

export const SystemNotiOptions = Object.keys(SystemNotiType).map(key => ({
  label: SystemNotiType[key],
  value: key,
}));

export const SHIP_STATUS = {
  211: 'Đang đi lấy hàng',
  203: 'Lấy hàng không thành công',
  202: 'Lấy hàng thành công',
  301: 'Nhận hàng vào bưu cục Nguồn',
  302: 'Xuất hàng đến trung tâm khai thác (trung tâm chia chọn)',
  303: 'Nhận hàng vào trung tâm khai thác (trung tâm chia chọn)',
  304: 'Xuất hàng khỏi trung tâm khai thác (trung tâm chia chọn)',
  309: 'Nhận hàng vào bưu cục phát hàng',
  601: 'Xuất hàng để đi giao',
  604: 'Giao hàng không thành công',
  666: 'Giao hàng thành công',
  605: 'Xác nhận chuyển hoàn',
  701: 'Xuất hàng khỏi bưu cục phát để trả về',
  702: 'Nhận hàng vào trung tâm khai thác (trung tâm chia chọn) để trả về',
  703: 'Xuất hàng khỏi trung tâm khai thác (trung tâm chia chọn) để trả về',
  704: 'Nhận hàng vào bưu cục trả hàng',
  705: 'Xuất hàng để trả về',
  707: 'Trả hàng không thành công',
  708: 'Trả hàng thành công',
  777: 'Thất lạc',
  1000: 'Hư hỏng (Damage)',
  'customer Cancel': 'Khách hàng hủy đơn',
};

export const ShipStatusOptions = Object.keys(SHIP_STATUS).map(key => ({
  label: SHIP_STATUS[key],
  value: key,
}));

const RESULT_SHIP = {
  1: 'Đã tạo đơn',
  2: 'Đã hủy',
};

export const ResultShipOptions = Object.keys(RESULT_SHIP).map(key => ({
  label: RESULT_SHIP[key],
  value: key,
}));

export const GAME_TYPE_MISSION = {
  1: 'Nổ hũ',
  2: 'Game Việt',
  3: 'Bắn cá',
  4: 'Casino',
  5: 'Thể thao',
  6: 'Xổ số',
};
export const TYPE_UPDATE_COIN = {
  ADD_COIN: 'Cộng xu',
  SUBTRACT_COIN: 'Trừ xu',
};

export const GameTypesMissionOption = Object.keys(GAME_TYPE_MISSION).map(key => ({
  label: GAME_TYPE_MISSION[key],
  value: key,
}));

export const PostType = {
  Banner: 'BANNER',
  Rules: 'RULES',
  Community: 'COMMUNITY',
  Terms: 'TERMS',
};

export const PostTypeAuthor = {
  Banner: 'UPDATE_BANNER',
  Rules: 'UPDATE_RULES',
  Community: 'UPDATE_POLICY',
  Terms: 'UPDATE_TERMS',
};

export const PostTypeOptionAuthor = [
  {
    label: 'Banner',
    title: PostTypeAuthor.Banner,
    value: PostType.Banner,
  },
  {
    label: 'Thể lệ',
    title: PostTypeAuthor.Rules,
    value: PostType.Rules,
  },
  {
    label: 'Tiêu chuẩn cộng đồng',
    title: PostTypeAuthor.Community,
    value: PostType.Community,
  },
  {
    label: 'Điều khoản',
    title: PostTypeAuthor.Terms,
    value: PostType.Terms,
  },
];

export const PostTypeOption = [
  {
    label: 'Banner',
    value: PostType.Banner,
  },
  {
    label: 'Thể lệ',
    value: PostType.Rules,
  },
  {
    label: 'Tiêu chuẩn cộng đồng',
    value: PostType.Community,
  },
  {
    label: 'Điều khoản',
    value: PostType.Terms,
  },
];

export const AdjustCoinType = {
  AddCoin: 'ADD_COIN',
  SubtractCoin: 'SUBTRACT_COIN',
};

export const AdjustCoinOption = [
  {
    label: 'Cộng',
    value: AdjustCoinType.AddCoin,
  },
  {
    label: 'Trừ',
    value: AdjustCoinType.SubtractCoin,
  },
];




export const HistoriStatus = {
  SUCCESS: 'Thành công',
  FAILED: 'Thất bại',
};

export const HistoriStatusOption = Object.keys(HistoriStatus).map(key => ({
  label: HistoriStatus[key],
  value: key,
}));

export const phoneStatus = {
  true: 'Đang hoạt động',
  false: 'Chưa hoạt động',
};

export const PhoneStatusOption = Object.keys(phoneStatus).map(key => ({
  label: phoneStatus[key],
  value: key,
}));

export const PERMISSIONS = {
  VIEW_LIST_PRODUCT_CATEGORY: 'Quyền tìm kiếm, xem danh mục sản phẩm',
  VIEW_LIST_CONTENT: 'Quyền tìm kiếm, xem nội dung hệ thống',
  VIEW_LIST_ADMIN: 'Quyền xem danh sách tài khoản admin',
  CREATE_ADMIN: 'Quyền thêm tài khoản admin',
  DELETE_ADMIN: 'Quyền xóa tài khoản admin',
  RESET_PASSWORD_ADMIN: 'Quyền thay đổi mật khẩu tài khoản admin (cá nhân)',
  VIEW_LIST_MEMBER: 'Quyền xem danh sách tài khoản hội viên',
  VIEW_DETAIL_MEMBER: 'Quyền xem thông tin chi tiết hội viên',
  VIEW_HISTORY_RECHARGE_BET_MEMBER: 'Quyền xem lịch sử Nap, Cược của hội viên',
  VIEW_HISTORY_COLLECT_COIN_MEMBER: 'Quyền tìm kiếm, xem lịch sử nhận xu Hội Viên',
  EXPORT_HISTORY_COLLECT_COIN_MEMBER: 'Quyền tải file data lịch sử nhận xu Hội viên',
  VIEW_HISTORY_ORDER_MEMBER: 'Quyền tìm kiếm, xem lịch sử mua hàng của Hội Viên',
  EXPORT_HISTORY_ORDER_MEMBER: 'Quyền tải file lịch sử mua hàng của Hội Viên',
  ADD_SUBTRACT_COIN_MEMBER: 'Quyền bù /trừ xu cho Hội viên',
  VIEW_ADDRESS_MEMBER: 'Quyền xem thông tin địa chỉ nhận hàng',
  UPDATE_PRODUCT_CATEGORY: 'Chỉnh sửa danh mục sản phẩm',
  VIEW_LIST_PRODUCT: 'Quyền tìm kiếm, xem số lượng đã đổi, còn lại của sản phẩm',
  VIEW_DETAIL_PRODUCT: 'Quyền xem chi tiết sản phẩm',
  UPDATE_PRODUCT: 'Quyền chỉnh sửa, thêm/bỏ sản phẩm',
  EXPORT_PRODUCT: 'Quyền tải file data sản phẩm',
  VIEW_DETAIL_MISSION: 'Quyền xem thông tin nhiệm vụ',
  UPDATE_MISSION: 'Quyền Tìm kiếm, Sửa, Thêm/Bỏ nhiệm vụ',
  ACTIVE_MISSION: 'Quyền bật tắt trạng thái hoạt động nhiệm vụ',
  UPDATE_ADDRESS: 'Quyền chỉnh sửa, thêm bỏ địa chỉ gửi và hoàn trả',
  VIEW_LIST_NO_ADDRESS_ORDER: 'Quyền xem thông tin đơn hàng (Không địa chỉ)',
  VIEW_STATUS_ORDER: 'Quyền xem trạng thái đơn hàng',
  CHANGE_STATUS_ORDER: 'Quyền thao tác, chỉnh sửa trạng thái đơn hàng',
  VIEW_DETAIL_ORDER: 'Quyền xem chi tiết đơn hàng',
  SEARCH_ORDER: 'Quyền tìm kiếm đơn hàng (không địa chỉ)',
  CREATE_SHIP_ORDER: 'Quyền tạo đơn vận chuyển',
  VIEW_DETAIL_SHIP_OF_ORDER: 'Quyền xem chi tiết vận chuyển của đơn hàng',
  VIEW_LIST_SHIP_ORDER: 'Quyền xem thông tin vận chuyển đơn hàng',
  VIEW_DETAIL_SHIP_ORDER: 'Quyền xem chi tiết đơn hàng vận chuyển',
  UPDATE_ADDRESS_SHIP_ORDER: 'Quyền Cập nhật địa chỉ đơn hàng vận chuyển',
  CANCEL_SHIP_ORDER: 'Quyền hủy đơn hàng vận chuyển',
  UPDATE_LINK_SYSTEM: 'Quyền chỉnh sửa, thêm/xóa link hệ thống',
  UPDATE_NOTIFICATION: 'Quyền thêm/xóa, chỉnh sửa thông báo hệ thống',
  UPDATE_MAINTAIN_SYSTEM: 'Quyền bật/tắt bảo trì hệ thống',
  UPDATE_BANNER: 'Quyền chỉnh sửa, thêm/xóa banner',
  UPDATE_RULES: 'Quyền chỉnh sửa, thêm/xóa thể lệ',
  UPDATE_TERMS: 'Quyền chỉnh sửa, thêm xóa điều khoản',
  UPDATE_POLICY: 'Quyền chỉnh sửa, thêm/xóa tiêu chuẩn cộng đồng',
};

export const PermissionOption = Object.keys(PERMISSIONS).map(key => ({
  label: PERMISSIONS[key],
  value: key,
}));

export const CountryCode = [
  {
    label: 'Việt Nam',
    value: 'VN',
  },
  {
    label: 'Thái Lan',
    value: 'TL',
  },
];

export const NETWORK_PHONE = {
  VINAPHONE: 'VINAPHONE',
  MOBIFONE: 'MOBIFONE',
  VIETTEL: 'VIETTEL',
  VIETNAMMOBILE: 'VIETNAMMOBILE',
  ITELECOM: 'ITELECOM',
  GMOBILE: 'GMOBILE',
};

export const NetworkPhoneOption = Object.keys(NETWORK_PHONE).map(key => ({
  label: NETWORK_PHONE[key],
  value: key,
}));

export const PHONE_SERVICE_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const TYPE_HISTORY = {
  BUY_PHONE: 'BUY_PHONE',
  REFUND_BUY_PHONE_FAILED: 'REFUND_BUY_PHONE_FAILED',
};

export const DepositMethodOption = [
  {
    label: 'Chuyển khoản ngân hàng',
    value: 'bank',
  },
  {
    label: 'Momo',
    value: 'momo',
  },
];

export const DepositStatus = {
  REJECTED: 'REJECTED',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
};

export const DepositStatusOption = Object.keys(DepositStatus).map(key => ({
  label: DepositStatus[key],
  value: key,
}));


export const TICKET_TYPE = {
  TRUE: 'true',
  FALSE: 'false',
};
export const TicketOption = Object.keys(TICKET_TYPE).map(key => ({
  label: capitalize(key),
  value: TICKET_TYPE[key],
}));

export const NOTIFICATION = {
  TELEGRAM: "VUI LÒNG KẾT NỐI TELEGRAM TẠI 'THÔNG TIN TÀI KHOẢN' ĐỂ TIẾP TỤC SỬ DỤNG DỊCH VỤ !",
  MAINTENANCE: `Hiện tại trang web đang bảo trì, Quý khách vui lòng không sử dụng dịch vụ. 
Nếu đã dùng và bị trừ tiền vui lòng gửi 'yêu cầu hỗ trợ' để được hoàn lại.`,
  CLOSE_TELEGRAM: "HIỆN TẠI DỊCH VỤ TELEGRAM CHƯA ĐƯỢC SỬ DỤNG! VUI LÒNG KHÔNG SỬ DỤNG DỊCH VỤ NÀY.",
  REFUND: "THÔNG BÁO: THUÊ DỊCH VỤ KHÔNG THÀNH CÔNG HỆ THỐNG SẼ TỰ ĐỘNG HOÀN TIỀN. ĐỐI VỚI ZALO(5-8P),ĐỐI VỚI DỊCH VỤ KHÁC(15-30P)"
}

export const TypeBusiness = {
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
}

export const COUPON_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
  AMOUNT: 'AMOUNT',
};


export const RESELLER_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};
export const REPORT_ORDER_STATUS = {
  REJECTED: "REJECTED",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
};

export const statusOptions = [
  { value: RESELLER_STATUS.APPROVED, label: 'Đã duyệt' },
  { value: RESELLER_STATUS.PENDING, label: 'Đang chờ' },
  { value: RESELLER_STATUS.REJECTED, label: 'Đã từ chối' },
];

export const statusOrderOptions = [
  { value: REPORT_ORDER_STATUS.SUCCESS, label: 'Thành công' },
  { value: REPORT_ORDER_STATUS.PROCESSING, label: 'Đang xử lý' },
  { value: REPORT_ORDER_STATUS.REJECTED, label: 'Thất bại' },
];