import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Image,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { DepartmentFormValidate } from 'utils/validation';
import CheckboxController from 'components/Form/CheckboxController';
import EditorController from 'components/Form/EditorController';
import Danger from 'assets/svg/danger.svg';
import { useQueryGetListCategory, useQueryGetListSubCategory } from 'services/category';
import { TypeBusiness } from 'constants/common';
import { mappingOptionSelect } from 'utils/mapping';
import { uploadPhotoProduct, useCreateProductWithMemberMutation } from 'services/product';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'components/Toast';

function CreateDepartment() {
  const history = useHistory();
  const [error, setError] = useState({
    thumbnailFile: null,
  });
  const [files, setFiles] = useState([]);
  const [isDragEnter, setIsDragEnter] = useState(false);


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDragEnter: () => {
      setIsDragEnter(true);
    },
    onDragLeave: () => {
      setIsDragEnter(false);
    },
    onDrop: acceptedFiles => {
      setIsDragEnter(false);
      setError({ thumbnailFile: null });
      setFiles([
        ...[acceptedFiles?.[0]].map(file => ({
          id: nanoid(),
          preview: URL.createObjectURL(file),
          file,
        })),
      ]);
    },
  });

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError: setErrorField,
    formState: { errors },
    trigger,
    clearErrors,
  } = useForm({
    resolver: yupResolver(DepartmentFormValidate),
    defaultValues: {},
  });
  const createProductWithMemberMutation = useCreateProductWithMemberMutation()

  const { data: categories } = useQueryGetListCategory(
    {
      type: watch('typeBusiness')?.value,
    },
    {
      enabled: !![TypeBusiness.PRODUCT, TypeBusiness.SERVICE].includes(watch('typeBusiness')?.value),
    }
  );
  const { data: subCategory } = useQueryGetListSubCategory(
    watch('typeSell')?.value,
    {},
    {
      enabled: !!watch('typeSell')?.value,
    }
  );
  const selectedCategory = watch('typeBusiness');
  const selectedSubCategory = watch('typeSell');

  const onRemove = index => {
    setFiles(prev => prev.filter((i, idX) => idX !== index));
  };

  const onUploadPhoto = async () => {
    const formData = new FormData();
    const filesUpload = files?.filter(item => item.file);
    let pathFiles = [];

    if (!isEmpty(filesUpload)) {
      filesUpload.map(item => {
        formData.append('productFile', item.file);
      });
      const response = await uploadPhotoProduct(formData);

      pathFiles = response?.data;

      return pathFiles;
    }
    return null;
  };

  const onSubmit = async values => {
    if (files.length === 0) {
      setError({ thumbnailFile: 'Hãy chọn ít nhất một ảnh' });
      return;
    }
    const photosPath = await onUploadPhoto();

    const newDataForm = {
      name: values.name,
      shortName: values.shortName,
      price: values.price,
      description: values.description,
      thumbnail: photosPath,
      subCategoryId: values.typeProduct.value,
      totalQuantity: values.totalQuantity,
      isReseller: values.isReseller
    };
    createProductWithMemberMutation.mutate(
      newDataForm,
      {
        onSuccess: () => {
          toast.showMessageSuccess('Tạo sản phẩm thành công');
          history.push('/admin/department');
        },
        onError: error => {
          toast.showMessageError('Tạo sản phẩm thất bại');
        },
      }
    )
  };


  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" fontWeight={600} fontSize={20}>
          Thêm gian hàng
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <form>
              <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }} mb={3}>
                <InputController control={control} name="name" placeholder="Tên gian hàng" isRequired styleContainer={{ pt: '4' }} />
                <SelectController
                  styleContainer={{ pt: '4' }}
                  control={control}
                  isRequired
                  name="typeBusiness"
                  placeholder="Loại hình kinh doanh"
                  isClearable
                  options={[
                    {
                      label: 'Bán sản phẩm',
                      value: 'PRODUCT',
                    },
                    {
                      label: 'Dịch vụ',
                      value: 'SERVICE',
                    },
                  ]}
                  onChange={() => {
                    setValue('typeSell', null);
                    setValue('typeProduct', null);
                  }}
                />
              </Flex>
              <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                {selectedCategory && (
                  <SelectController
                    styleContainer={{ pt: '4' }}
                    control={control}
                    isRequired
                    name="typeSell"
                    placeholder="Loại gian hàng"
                    isClearable
                    onChange={() => {
                      setValue('typeProduct', null);
                    }}
                    options={mappingOptionSelect(categories?.data, 'title', 'slug')}
                  />
                )}
                {selectedSubCategory && (
                  <SelectController
                    styleContainer={{ pt: '4' }}
                    control={control}
                    isRequired
                    name="typeProduct"
                    placeholder="Loại sản phẩm - Chiết khấu cho sàn"
                    isClearable
                    options={mappingOptionSelect(subCategory?.data, 'title', '_id')}
                  />
                )}
              </Flex>
              <Flex gap={4} flexWrap={{ base: 'wrap', md: 'nowrap' }} mb={3}>
                <InputController control={control} name="price" placeholder="Giá Bán" isRequired styleContainer={{ pt: '4' }} />
                <InputController control={control} name="totalQuantity" placeholder="Số lượng" isRequired styleContainer={{ pt: '4' }} />
              </Flex>
              <CheckboxController
                label="Bạn có muốn cho reseller bán hàng không?"
                name={'isReseller'}
                control={control}
                options={[]}
                styleContainer={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  gap: 3,
                  alignItems: 'center',
                  justifyContent: 'revert',
                  pb: '4',
                  width: 'fit-content',
                }}
              />
              <InputController
                isRequired
                control={control}
                name="shortName"
                label="Mô tả ngắn"
                placeholder="Nhập mô tả"
                styleContainer={{ pt: '4' }}
              />
              <EditorController
                styleContainer={{ pt: '4' }}
                control={control}
                name="description"
                label="Nội dung bài viết"
                isRequired
              // uploadApiUrl={API_ROUTES.UPLOAD_IMAGE}
              />
              <FormControl pt={4}>
                <FormLabel minW="150px">Ảnh</FormLabel>
                <Box
                  {...getRootProps()}
                  cursor="pointer"
                  borderWidth="1px"
                  borderStyle="dashed"
                  borderRadius="12px"
                  padding="55px"
                  textAlign="center"
                  height="210px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <input {...getInputProps()} />
                  <BsUpload size={40} />
                  {isDragEnter ? (
                    <p>Thả ảnh của bạn</p>
                  ) : (
                    <>
                      <p>
                        <Text as="span" color="red.400">
                          Tải tập tin lên
                        </Text>
                        &nbsp;hoặc kéo và thả
                      </p>
                    </>
                  )}
                  <Text fontSize="12px" color="gray.500" pt={1}>
                    Kích thước lớn hơn 320px
                  </Text>
                </Box>
                <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                  {files?.map((file, index) => (
                    <GridItem w={'100px'} key={file.id} position="relative">
                      <Image src={file.preview} w="100px" h="100px" objectFit="contain" />
                      <IconButton bg="transparent" position="absolute" top="0" right="0" onClick={() => onRemove(index)}>
                        <BsXCircle size={16} color="red" />
                      </IconButton>
                    </GridItem>
                  ))}
                </Grid>
                {!!error.thumbnailFile && (
                  <Text pt={1} color={'red.500'} fontSize="13px">
                    {error.thumbnailFile}
                  </Text>
                )}
              </FormControl>
            </form>
            <Flex alignItems={'center'} gap={5} rounded="md" bg="#FFFDF8" border={'1px solid #FFC700'} p="4">
              <Image src={Danger} alt="Danger" w="30px" h="30px" objectFit="contain" />
              <Flex direction="column" gap={3}>
                <Text fontSize="14px" fontWeight="bold" color="blue.500">
                  Vui lòng đọc kỹ trước khi tạo mới:
                </Text>
                <UnorderedList>
                  <ListItem>
                    Vì bên mình là sàn thương mại điện tử, nên nếu sản phẩm của bạn không bán trực tiếp trên site được thì sẽ không được
                    duyệt. Ví dụ các sản phẩm không thể số hóa thành text file, phần mềm không có API để get hay active key (hãy liên hệ để
                    coder 2 bên có thể map với nhau nhé)....
                  </ListItem>
                  <ListItem>
                    Không đăng thông tin liên hệ nào khác lên gian hàng (kể cả hình ảnh), không nhắn tin cách thức liên hệ ngoài site. Mong
                    các bạn hiểu, bên mình là sàn, chi phí rất nhiều cho đội ngũ kỹ thuật, hỗ trợ, marketing, server, nên hi vọng chúng ta
                    có thể hợp tác đôi bên cùng có lợi, về lâu về dài bên mình cũng cần có chi phí duy trì, phát triển nền tảng... nên rất
                    cần đối tác hiểu, và có thể gắn bó lâu dài với site
                  </ListItem>
                  <ListItem>
                    Nếu có yêu cầu gì thêm cho phần bán hàng, các bạn cứ nhắn tin cho support, bên mình sẵn sàng hỗ trợ sao cho các bạn bán
                    được thuận tiện nhất.
                  </ListItem>
                  <ListItem>
                    Các quy định khác về gian hàng các bạn đọc thêm ở đây: <Link>Quy định về gian hàng và các mặt hàng bị cấm.</Link>
                  </ListItem>
                </UnorderedList>
              </Flex>
            </Flex>
            <Flex w={'100%'} pt={6} alignItems="flex-end" justifyContent="flex-end">
              <Button
                w={'100%'}
                onClick={() => {
                  history.goBack();
                }}
              >
                Quay lại
              </Button>
              <Button
                w={'100%'}
                colorScheme="blue"
                ml={3}
                // isLoading={createProductMutation.isPending || updateProductMutation.isPending}
                onClick={handleSubmit(onSubmit)}
              >
                {'Tạo mới'}
              </Button>
            </Flex>
          </Stack>
        </CardBody>
      </Card>
      {/* {isCreateModalOpen && (
          <CreateCategoryModal userDetail={sizeEditing} isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />
        )}
        {isUpdatePasswordModalOpen && (
          <UpdatePassword userDetail={sizeEditing} isOpen={isUpdatePasswordModalOpen} onClose={handelCloseModal} />
        )}
        {isAssignPermissionModalOpen && (
          <AssignPermission userDetail={sizeEditing} isOpen={isAssignPermissionModalOpen} onClose={handelCloseModal} refetch={refetch} />
        )} */}
    </Flex>
  );
}

export default CreateDepartment;
