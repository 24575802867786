import * as yup from 'yup';

import { ErrorForm } from 'constants/error';
import { COUPON_TYPE, EMAIL_REGEX, MISSION_TYPE, PASSWORD_REGEX, PermissionOption, PostType, URL_REGEX } from 'constants/common';

export const schemaTest = {
  isIncludedLetter: value => value?.match(/[a-z]/g),
  isIncludedCapitalLetter: value => value?.match(/[A-Z]/g),
  isIncludedNumber: value => value?.match(/[0-9]/g),
  isIncludedSymbol: value => value?.match(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/g),
  isValidPassword: value => {
    const passed = [];
    if (value.length >= 10) {
      passed.push('safeLength');
    }
    if (schemaTest.isIncludedLetter(value)) {
      passed.push('isIncludedLetter');
    }
    if (schemaTest.isIncludedCapitalLetter(value)) {
      passed.push('isIncludedCapitalLetter');
    }
    if (schemaTest.isIncludedNumber(value)) {
      passed.push('isIncludedNumber');
    }
    if (schemaTest.isIncludedSymbol(value)) {
      passed.push('isIncludedSymbol');
    }
    return {
      isValid: passed.length === 5,
      passed,
    };
  },
  isValidAlias: value => (!value ? true : value?.match(/^(?!-)([A-Za-z0-9-](?!.*--)){0,62}[A-Za-z0-9]$/g)),
  isValidEmail: value =>
    !value
      ? true
      : value?.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      ),
  isValidGoogleMapUrl: value => (!value ? true : value?.match(/^(https:\/\/)(goo\.gl|google\.com)\/maps\/([^\s\\]+)$/g)),
  isValidAliasName: value => value.match(/^(?!-)([A-Za-z0-9-](?!.*--)){0,62}[A-Za-z0-9]$/g),
  isValidDomain: value => value.match(/^(((?!-)[A-Za-z0-9-]{0,62}[A-Za-z0-9])\.)+((?!-)[A-Za-z0-9-]{1,62}[A-Za-z0-9])(\.)?$/g),
  isValidPassword: value => value.match(PASSWORD_REGEX),
};

// Validate form
export const WhitelistIPFormValidate = yup.object().shape({
  ip: yup
    .string()
    .nullable()
    .required(ErrorForm.Required)
    .matches(
      /^(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/,
      ErrorForm.FieldInvalid
    ),
});
export const CustomerApiFormValidate = yup.object().shape({
  username: yup
    .string()
    .min(5, ErrorForm.MaximumUsernameLength)
    .max(30, ErrorForm.MaximumUsernameLength)
    .nullable()
    .required(ErrorForm.Required),
});
export const RegisterFormValidate = yup.object().shape({
  username: yup
    .string()
    .min(5, ErrorForm.MaximumUsernameLength)
    .max(30, ErrorForm.MaximumUsernameLength)
    .nullable()
    .required(ErrorForm.Required),
  fullname: yup
    .string()
    .min(5, ErrorForm.MaximumUsernameLength)
    .max(30, ErrorForm.MaximumUsernameLength)
    .nullable()
    .required(ErrorForm.Required),
  email: yup
    .string()
    .max(255, ErrorForm.MaxLength(255))
    .email(ErrorForm.EmailInvalid)
    .matches(EMAIL_REGEX, {
      message: ErrorForm.EmailInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  phone: yup.string().min(10, ErrorForm.MaximumPhoneLength).max(10, ErrorForm.MaximumPhoneLength).nullable().notRequired(),
  password: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  passwordConf: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .oneOf([yup.ref('password'), null], ErrorForm.PasswordNotSame)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  // contactChannel: yup.object().nullable().required(ErrorForm.Required),
  // contactUsername: yup.string().nullable().required(ErrorForm.Required),
});

export const ResetPasswordValidate = yup.object().shape({
  email: yup
    .string()
    .max(255, ErrorForm.MaxLength(255))
    .email(ErrorForm.EmailInvalid)
    .matches(EMAIL_REGEX, {
      message: ErrorForm.EmailInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
});

export const SetNewPasswordValidate = yup.object().shape({
  otp: yup.string().nullable().required(ErrorForm.Required),
  password: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
});

export const LoginFormValidate = yup.object().shape({
  username: yup.string().nullable().required(ErrorForm.Required),
  password: yup.string().nullable().required(ErrorForm.Required),
  // brand: yup.mixed().nullable().required(ErrorForm.Required),
});

export const AssignPermissionFormValidate = yup.object().shape({
  ...PermissionOption.reduce((acc, field) => {
    acc[field?.value] = yup.boolean().nullable();
    return acc;
  }, {}),
});

export const ChangePasswordFormValidate = yup.object().shape({
  password: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  newPassword: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  passwordConf: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .oneOf([yup.ref('newPassword'), null], ErrorForm.PasswordNotSame)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
});

export const UpdateMemberProfileFormValidate = yup.object().shape({
  username: yup.string().nullable().notRequired(),
  fullname: yup
    .string()
    .min(5, ErrorForm.MaximumUsernameLength)
    .max(30, ErrorForm.MaximumUsernameLength)
    .nullable()
    .required(ErrorForm.Required),
  phone: yup.string().min(10, ErrorForm.MaximumPhoneLength).max(10, ErrorForm.MaximumPhoneLength).nullable().required(ErrorForm.Required),
});

export const UpdateProfileFormValidate = yup.object().shape({
  fullname: yup
    .string()
    .min(5, ErrorForm.MaximumUsernameLength)
    .max(30, ErrorForm.MaximumUsernameLength)
    .nullable()
    .required(ErrorForm.Required),
  codeNumber: yup
    .string()
    .min(9, ErrorForm.CodeNumberLength)
    .max(12, ErrorForm.CodeNumberLength)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  frontSideFile: yup.mixed().required(ErrorForm.Required),
  backSideFile: yup.mixed().required(ErrorForm.Required),
});

export const TicketFormValidate = yup.object().shape({
  subject: yup.string().required(ErrorForm.Required),
  content: yup.string().required(ErrorForm.Required),
  type: yup.object().nullable().required(ErrorForm.Required),
});

export const GetCodeNativeFormValidate = yup.object().shape({
  widget: yup.object(),
  fontSize: yup.object(),
  color: yup.string(),
});

export const SizeFormValidate = yup.object().shape({
  name: yup.string().required(ErrorForm.Required),
});
export const ShippingFeeFormValidate = yup.object().shape({
  name: yup.object().required(ErrorForm.Required),
  fee: yup.string().required(ErrorForm.Required),
});

export const ProductFormValidate = yup.object().shape({
  name: yup.string().required(ErrorForm.Required),
  price: yup.string().required(ErrorForm.Required),
  totalQuantity: yup.string().required(ErrorForm.Required),
  category: yup.object().required(ErrorForm.Required),
  variantLabel_1: yup.string().when('informations', {
    is: value => {
      const isValueExists = value?.find(item => !!item?.color);

      if (isValueExists) return true;

      return false;
    },
    then: schema => schema.required(ErrorForm.Required),
  }),
  variantLabel_2: yup.string().when('informations', {
    is: value => {
      const isValueExists = value?.find(item => !!item?.size);

      if (isValueExists) return true;

      return false;
    },
    then: schema => schema.required(ErrorForm.Required),
  }),
  informations: yup.array().of(
    yup.object().shape({
      // color: yup.string().required(ErrorForm.Required),
      // size: yup.string().required(ErrorForm.Required),
      quantity: yup.string().required(ErrorForm.Required),
    })
  ),
  metaData: yup.array().of(
    yup.object().shape({
      key: yup.string().required(ErrorForm.Required),
      value: yup.string().required(ErrorForm.Required),
    })
  ),
});

export const CategoryFormValidate = yup.object().shape({
  title: yup.string().required(ErrorForm.Required),
  description: yup.string().required(ErrorForm.Required),
});

export const CodeServiceFormValidate = yup.object().shape({
  name: yup.string().required(ErrorForm.Required),
});

export const ServiceFormValidate = yup.object().shape({
  title: yup.string().required(ErrorForm.Required),
  price: yup.string().required(ErrorForm.Required),
  store: yup.mixed().nullable().required(ErrorForm.Required),
  thumbnails: yup.string().required(ErrorForm.Required),
  description: yup.string().notRequired(),
});

export const PhoneFormValidate = yup.object().shape({
  phone: yup.string().required(ErrorForm.Required),
  countryCode: yup.mixed().nullable().required(ErrorForm.Required),
});

export const FastServiceFormValidate = yup.object().shape({
  serviceId: yup.mixed().nullable().required(ErrorForm.Required),
  countryCode: yup.mixed().nullable().required(ErrorForm.Required),
});

export const RentSelectServiceFormValidate = yup.object().shape({
  serviceId: yup.mixed().nullable().required(ErrorForm.Required),
  network: yup.mixed().nullable().required(ErrorForm.Required),
});

export const AddProductFormValidate = yup.object().shape({
  name: yup.string().required(ErrorForm.Required),
  price: yup.string().required(ErrorForm.Required),
});


export const AdminFormValidate = yup.object().shape({
  username: yup
    .string()
    .min(5, ErrorForm.MaximumUsernameLength)
    .max(30, ErrorForm.MaximumUsernameLength)
    .nullable()
    .required(ErrorForm.Required),
  role: yup.mixed().required(ErrorForm.Required),
  brand: yup.mixed().when('role', {
    is: value => {
      if (value?.value === 'manager') return true;
      return false;
    },
    then: schema => schema.required(ErrorForm.Required),
    otherwise: schema => schema,
  }),
  password: yup.string().required(ErrorForm.Required),
});

export const BookmarkerFormValidate = yup.object().shape({
  bookMaker: yup.object().required(ErrorForm.Required),
  depositPage: yup.string().nullable(),
  homepage: yup.string().matches(URL_REGEX, 'Đường dẫn không hợp lệ').required(ErrorForm.Required),
});

export const VendorFormValidate = yup.object().shape({
  name: yup.string().required(ErrorForm.Required),
});

export const AddressFormValidate = yup.object().shape({
  name: yup.string().nullable().required(ErrorForm.Required),
  phone: yup.string().nullable().required(ErrorForm.Required),
  address: yup.string().nullable().required(ErrorForm.Required),
  district: yup.object().nullable().required(ErrorForm.Required),
  province: yup.object().nullable().required(ErrorForm.Required),
  ward: yup.object().nullable().required(ErrorForm.Required),
});

export const UpdateAddressFormValidate = yup.object().shape({
  name: yup.string().nullable().required(ErrorForm.Required),
  phone: yup.string().nullable().required(ErrorForm.Required),
  address: yup.string().nullable().required(ErrorForm.Required),
  district: yup.object().nullable().required(ErrorForm.Required),
  province: yup.object().nullable().required(ErrorForm.Required),
  ward: yup.object().nullable().required(ErrorForm.Required),
  addressType: yup.object().nullable().required(ErrorForm.Required),
});

export const SettingNotificationFormValidate = yup.object().shape({
  name: yup.string().nullable().required(ErrorForm.Required),
});

export const MissionFormValidate = yup.object().shape({
  title: yup.string().nullable().required(ErrorForm.Required),
  type: yup.mixed().required(ErrorForm.Required),
  totalAmountToCollect: yup.string().nullable().required(ErrorForm.Required),
  coinCollect: yup.string().nullable().required(ErrorForm.Required),
  level: yup.mixed().when('type', {
    is: value => [MISSION_TYPE.BET_IN_DAY, MISSION_TYPE.BET_IN_WEEK].some(item => item === value?.value),
    then: schema => schema.required(ErrorForm.Required),
    otherwise: schema => schema,
  }),
  gameTypes: yup.mixed().when('type', {
    is: value => [MISSION_TYPE.BET_IN_DAY, MISSION_TYPE.BET_IN_WEEK].some(item => item === value?.value),
    then: schema => schema.required(ErrorForm.Required),
    otherwise: schema => schema,
  }),
  description: yup.string().nullable(),
});
export const TicketsFormValidate = yup.object().shape({
  title: yup.string().nullable().required(ErrorForm.Required),
  content: yup.string().nullable().required(ErrorForm.Required),
});

export const OrderShipFormValidate = yup.object().shape({
  username: yup.string().nullable(),
  orderNo: yup.string().nullable().required(ErrorForm.Required),
  productPrice: yup.string(),
});

export const StatusOrderFormValidate = yup.object().shape({
  status: yup.mixed().nullable().required(ErrorForm.Required),
});

export const SystemNotificationFormValidate = yup.object().shape({
  // content: yup.string().nullable().required(ErrorForm.Required),
  // title: yup.string().nullable().required(ErrorForm.Required),
  // type: yup.string().nullable().required(ErrorForm.Required),
  // avatar: yup.string().notRequired(),
  productID: yup.object().nullable().required(ErrorForm.Required),
});

export const SystemNotificationFormValidateCP = yup.object().shape({
  content: yup.string().nullable().required(ErrorForm.Required),
  title: yup.string().nullable().required(ErrorForm.Required),
  type: yup.mixed().required(ErrorForm.Required),
});

export const SystemContentValidate = yup.object().shape({
  type: yup.object().nullable().required(ErrorForm.Required),
  order: yup
    .string()
    .nullable()
    .when('type', {
      is: value => {
        const isValid = value?.value === PostType.Banner;

        if (isValid) return true;

        return false;
      },
      then: schema => schema.required(ErrorForm.Required),
    }),
  title: yup
    .string()
    .nullable()
    .when('type', {
      is: value => {
        const isValid = value?.value !== PostType.Banner;

        if (isValid) return true;

        return false;
      },
      then: schema => schema.required(ErrorForm.Required),
    }),
  body: yup
    .string()
    .nullable()
    .when('type', {
      is: value => {
        const isValid = value?.value !== PostType.Banner;

        if (isValid) return true;

        return false;
      },
      then: schema => schema.required(ErrorForm.Required),
    }),
});

export const AdjustCoinFormValidate = yup.object().shape({
  type: yup.object().nullable().required(ErrorForm.Required),
  coin: yup
    .number()
    .transform((cv, ov) => (typeof ov === 'string' && ov.trim() === '' ? undefined : cv))
    .positive('Coin phải là một số dương')
    .integer()
    .nullable()
    .required(ErrorForm.Required),
});

export const BankAccountFormValidate = yup.object().shape({
  bank: yup.object().nullable().required(ErrorForm.Required),
  accountNo: yup.string().required(ErrorForm.Required),
  accountName: yup.string().required(ErrorForm.Required),
});

export const DepositFormValidate = yup.object().shape({
  amount: yup
    .number()
    .typeError('Trường này là bắt buộc')
    .required('Trường này là bắt buộc')
    .test(
      'isMultipleOfTen',
      'Số xu phải là bội số của 10000, không nhỏ hơn 50000 và không lớn hơn 50000000',
      value => value % 10000 === 0 && value >= 50000 && value < 50000000
    ),
  // type: yup.object().nullable().required(ErrorForm.Required),
});

export const DepartmentFormValidate = yup.object().shape({
  name: yup.string().nullable().required(ErrorForm.Required),
  typeBusiness: yup.mixed().nullable().required(ErrorForm.Required),
  typeSell: yup.mixed().nullable().required(ErrorForm.Required),
  typeProduct: yup.mixed().nullable().required(ErrorForm.Required),
  price: yup.number().nullable().required(ErrorForm.Required),
  totalQuantity: yup.number().nullable().required(ErrorForm.Required),
  isReseller: yup.boolean().nullable(),
  shortName: yup.string().nullable().required(ErrorForm.Required),
  description: yup.string().nullable().required(ErrorForm.Required),
})
export const DepartmentFormUpdateValidate = yup.object().shape({
  name: yup.string().nullable().required(ErrorForm.Required),
  shortName: yup.string().nullable().required(ErrorForm.Required),
  price: yup.number().nullable().required(ErrorForm.Required),
  description: yup.string().nullable().required(ErrorForm.Required),
  totalQuantity: yup.number().nullable().required(ErrorForm.Required),
  isReseller: yup.boolean().nullable(),
})

export const CouponFormValidate = yup.object().shape({
  code: yup.string().nullable().required(ErrorForm.Required),
  max: yup
    .number()
    .transform((value) => Number.isNaN(value) ? null : value)
    .transform(value => (Number.isNaN(value) ? null : value))
    .nullable()
    .required(ErrorForm.Required),
  quantityUsing: yup
    .number()
    .transform((value) => Number.isNaN(value) ? null : value)
    .transform(value => (Number.isNaN(value) ? null : value))
    .min(1, 'Số lần sử dụng phải lớn hơn 0')
    .nullable()
    .required(ErrorForm.Required),
  amount: yup
    .number()
    .transform(value => (Number.isNaN(value) ? null : value))
    .min(1, 'Tỉ lệ giảm giá phải lớn hơn 0')
    .max(100, 'Tỉ lệ giảm giá phải nhỏ hơn 100')
    .when('type.value', {
      is: value => {
        return value === COUPON_TYPE.PERCENTAGE;
      },
      then: () =>
        yup
          .number()
          .transform(value => (Number.isNaN(value) ? null : value))
          .nullable()
          .required(ErrorForm.Required),
      otherwise: () => yup.number().notRequired(),
    }),
});